import React, { useEffect, useState } from 'react'
import Layout from '../../../master/Layout'
import { toast, ToastContainer } from 'react-toastify';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { Link } from 'react-router-dom';
import { BASEURL } from '../../../Config';
import { useChangeStatusMutation, useDeleteFaqMutation, useDownloadPdfFaqMutation, useFaqCategoryListMutation, useFaqListMutation } from '../../../services/ProjectApi';

export default function FAQ() {

    const notifyError = (message)=>toast.error(message);
    const notifySuccuess = (message)=>toast.success(message);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = getToken();

    const [list,setList] = useState([]);
    const [searchInput,setSearchInput] = useState({keyword:'',faqCategoryId:'',status:''});

    const [faqCategoryList,setFaqCategoryList] = useState({});

    const handleApiError = useApiErrorHandler();

    const [FaqListMutation] = useFaqListMutation();
    const [DeleteFaqMutation] = useDeleteFaqMutation();
    const [ChangeStatusMutation] = useChangeStatusMutation();


    const getList = async ()=>{
        try{
        const response = await FaqListMutation({token,keyword:searchInput.keyword,faqCategoryId:searchInput.faqCategoryId,status:searchInput.status,page:currentPage});
       
        // console.log(response.error.data.statusCode)
      if(response.error){

        handleApiError(response.error)
      }
       
        if(response.data.status){
            setList(response.data.data);
            setTotalPages(response.data.pagination.last_page);
        }
    } catch (error) {
        console.log(error)
        notifyError("An unexpected error occurred.");
    }
    }

    const [FaqCategoryListMutation] = useFaqCategoryListMutation();
    const getFaqCategory = async ()=>{
        const FaqCategoryListMutationResp = await FaqCategoryListMutation({token,status:'active',page:1,limit:1000})
        if(FaqCategoryListMutationResp.data.status){
            setFaqCategoryList(FaqCategoryListMutationResp.data.data);
        }
    }

    useEffect(()=>{
        
        getFaqCategory();
        getList();
     
    },[currentPage]);

    useEffect(()=>{
        const delayFun = setTimeout(()=>{
            getList();
        },0);
        return () => clearTimeout(delayFun);
    },[searchInput]);

    const deleteData = async (id)=>{
        if(window.confirm('Are you sure you want to delete this ?')){
            const DeleteFaqMutationResp = await DeleteFaqMutation({token,id});
            console.log(DeleteFaqMutationResp)
            if(DeleteFaqMutationResp.data.status){
                notifySuccuess(DeleteFaqMutationResp.data.message);
                getList();
            } else {
                notifyError(DeleteFaqMutationResp.data.message);
            }
        }
    }

    const changeStatus = async (table_name, id, status)=>{
        const form_data = {table_name, id, status};
        const ChangeStatusMutationResp = await ChangeStatusMutation({token, form_data});
        if(ChangeStatusMutationResp.data.status){
            notifySuccuess(ChangeStatusMutationResp.data.message);
            getList();
        } else {
            notifyError(ChangeStatusMutationResp.data.message);
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const [downloadPdfFaq] = useDownloadPdfFaqMutation();

    const downloadPdfFile = async () => {
      try {
        const response = await downloadPdfFaq({ token });
    
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement('a');
          a.href = url;
          a.download = 'faq-list.pdf';
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          console.error('Failed to download file:', response.error);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    

  return (
    <Layout>
    <ToastContainer />
    <div className="card">
        <div className="card-header">
            <div className="row">
                <div className="col-md-6 mt-2 pagetitle">
                    <h3>FAQ List</h3> 
                </div>
                <div className="col-md-3 text-end mt-3">
                {/* <button className="btn btn-success mx-2" onClick={downloadPdfFile}>
        Download CSV
    </button> */}
    </div>

                <div className="col-md-3 text-end mt-3">
                    <Link className="btn btn-primary" to={`/admin/FAQ/create`}>Add FAQ</Link>
                </div>
            </div>
        </div>
        <div className="card-body ">
            <div className="row mb-3">
                <div className="col-md-3">
                    <input 
                        type='text' 
                        className='form-control' 
                        name='search' 
                        id='search' 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, keyword: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        value={searchInput.keyword} 
                        placeholder='Search here..' 
                    />
                </div>
                <div className="col-md-3">
                    <select 
                        name='status' 
                        value={searchInput.status} 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, status: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        className='form-control'
                    >
                        <option value=''>--Please select--</option>
                        <option value='active'>Active</option>
                        <option value='inactive'>Inactive</option>
                    </select>
                </div>
                <div className="col-md-3">
                    <select 
                        name='faqCategoryId' 
                        value={searchInput.faqCategoryId} 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, faqCategoryId: e.target.value})
                        }} 
                        className='form-control'
                    >
                        <option value=''>--Please select FAQ Category--</option>
                        {faqCategoryList.length > 0 && faqCategoryList.map((value,index)=>(
                            <option key={index} value={value._id}>{value.title}</option>
                        ))}
                    </select>
                </div>
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Question</th>
                        <th scope="col">FAQ Category</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    list.length > 0 ? list.map((value, index)=>(
                        <tr key={index}>
                            {/* <td>{(currentPage - 1) * 3 + index + 1}</td> */}
                            <td>{index + 1}</td>
                            <td>{value.question}</td>
                            <td>{  value.faqCategoryId.title }</td>
                            <td>{value.status === 'active' ? (
                                <Link onClick={()=>{ changeStatus('Faq', value._id, 'inactive') }} className='btn btn-success text-white'>Active</Link>
                            ):(
                                <Link onClick={()=>{ changeStatus('Faq', value._id, 'active') }} className='btn btn-danger text-white'>Inactive</Link>
                            )}</td>
                            <td>
                                <Link to={`/admin/faq/${value._id}/edit`} className='btn btn-warning btn-sm mx-1'><i className='bi bi-pencil'></i></Link>
                                <Link onClick={()=>{ deleteData(value._id) }} className='btn btn-danger btn-sm mx-1'><i className="bi bi-trash"></i></Link>
                            </td>
                        </tr>
                    )):(
                        <tr>
                            <td colSpan="6" className='text-center'>No Record Found</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            {totalPages > 1 && (
                        <div className="d-flex justify-content-center">
                            <nav>
                                <ul className="pagination">
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    )}
        </div>
    </div>
</Layout>
  )
}
