import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useEditFaqCategoryMutation, useEditPriceAnesthesiologistMutation, useEditPricePathologyMutation, useUpdateFaqCategoryMutation, useUpdatePriceAnesthesiologistMutation, useUpdatePricePathologyMutation } from '../../../../services/ProjectApi';
import { BASEURL } from '../../../../Config';
import { getToken } from '../../../../services/Token';
import { useApiErrorHandler } from '../../../../utils/useApiErrorHandler';
import Layout from '../../../../master/Layout';
import { formDataToJson } from '../../../../services/utility';

export default function EditPricePathology() {

const {id} = useParams()

const [EditPricePathologyMutation] =  useEditPricePathologyMutation();

const viewDetail = async(id)=>{
  const editResponse =   await EditPricePathologyMutation({token,id})

  if(editResponse.data.status){
    setInputData(editResponse.data.data)

    setImagePreview(BASEURL+''+editResponse.data.data.image)
  }
}

useEffect(()=>{
    viewDetail(id);
},[])


    const nofitySuccess = (message)=>toast.success(message)
    const notifyError = (message)=>toast.error(message)

    const [inputData,setInputData] = useState({});
    const [errorData,setErrorData] = useState({});
    const [imagePreview,setImagePreview] = useState('');

    const validateForm = ()=>{
        const errors = {}

        if (!inputData.nonNeoplasticPrice || inputData.nonNeoplasticPrice.trim() === '') errors.nonNeoplasticPrice = 'Non Neoplastic Price field is required';
        if (!inputData.neoplasticPrice || inputData.neoplasticPrice.trim() === '') errors.neoplasticPrice = 'Neoplastic Price field is required';
        if (!inputData.operativefrozenPrice || inputData.operativefrozenPrice.trim() === '') errors.operativefrozenPrice = 'Operative Frozen Price field is required';

        setErrorData(errors)
        return Object.keys(errors).length === 0;
    }


    const [UpdatePricePathologyMutation] = useUpdatePricePathologyMutation();

const token = getToken();

const handleApiError =  useApiErrorHandler();

const fileInputRef  = useRef(null);

const updateHandler = async (e)=>{
    e.preventDefault();
   if(validateForm()){
    const form_data = new FormData(e.currentTarget);

    form_data.append('_id',id)
    form_data.append('_method','PUT')

     const data = formDataToJson(form_data)
      const updateResponse = await UpdatePricePathologyMutation({id,token,data})

      if(updateResponse.error){
        handleApiError(updateResponse.error)
      }
      if(updateResponse.data.status){

        setImagePreview('')

        nofitySuccess(updateResponse.data.message)
        
      }else{
        if(updateResponse.data.errors){
            setErrorData(updateResponse.data.errors)
        }
        notifyError(updateResponse.data.message);
      }

   }
}



  return (
   <Layout>
     <div className='card'>
        <div className='card-header row'>
        <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Edit Price Pathology Form</h5>
        </div>
        <div className='col-md-6 text-end'>
            <Link to={`/admin/price-management-pathology`} className='btn btn-danger'>Back</Link>
        </div>
        </div>
        <div className='card-body container'>
            <form className='container' onSubmit={updateHandler}>
            <ToastContainer />


            <div className='form-group'>
                    <label htmlFor='nonNeoplasticPrice'>Non Neoplastic Price *</label>
                    <input type='text'
                  
                    onChange={(e) => {
                        const { value } = e.target;
                     
                        const numericValue = value.replace(/\D/g, "");
                        setInputData({ ...inputData, nonNeoplasticPrice: numericValue });

                        if (value !== numericValue) {
                          setErrorData({
                            ...errorData,
                            nonNeoplasticPrice: "Please enter only digits",
                          });
                        } else {
                          setErrorData({ ...errorData, nonNeoplasticPrice: "" });
                        }
                      }}
                    
                    
                    className={`form-control ${errorData.nonNeoplasticPrice ? 'is-invalid':''}`} name='nonNeoplasticPrice' value={inputData.nonNeoplasticPrice} id='nonNeoplasticPrice' />
                {errorData.nonNeoplasticPrice && (
                    <div className='invalid-feedback'>{errorData.nonNeoplasticPrice}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='neoplasticPrice'>Neoplastic Price *</label>
                    <input type='text'
                  
                    onChange={(e) => {
                        const { value } = e.target;
                     
                        const numericValue = value.replace(/\D/g, "");
                        setInputData({ ...inputData, neoplasticPrice: numericValue });

                        if (value !== numericValue) {
                          setErrorData({
                            ...errorData,
                            neoplasticPrice: "Please enter only digits",
                          });
                        } else {
                          setErrorData({ ...errorData, neoplasticPrice: "" });
                        }
                      }}
                    
                    
                    className={`form-control ${errorData.neoplasticPrice ? 'is-invalid':''}`} name='neoplasticPrice' value={inputData.neoplasticPrice} id='neoplasticPrice' />
                {errorData.neoplasticPrice && (
                    <div className='invalid-feedback'>{errorData.neoplasticPrice}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='operativefrozenPrice'>Operative Frozen operativefrozenPrice *</label>
                    <input type='text'
                       onChange={(e) => {
                        const { value } = e.target;
                     
                        const numericValue = value.replace(/\D/g, "");
                        setInputData({ ...inputData, operativefrozenPrice: numericValue });

                        if (value !== numericValue) {
                          setErrorData({
                            ...errorData,
                            operativefrozenPrice: "Please enter only digits",
                          });
                        } else {
                          setErrorData({ ...errorData, operativefrozenPrice: "" });
                        }
                      }}
                    
                    className={`form-control ${errorData.operativefrozenPrice ? 'is-invalid':''}`} name='operativefrozenPrice' value={inputData.operativefrozenPrice} id='operativefrozenPrice' />
                {errorData.operativefrozenPrice && (
                    <div className='invalid-feedback'>{errorData.operativefrozenPrice}</div>
                )}
                </div>

              
             

                <div className=' my-3'>
                <input type='submit' className='btn btn-primary mr-3' style={{ marginRight:`5px` }} value={`Submit`} />
                <Link to={`/admin/price-management-pathology`} className='btn btn-danger ml-3'>Back</Link>
        </div>
           
            </form>

      
      
        </div>
    </div>
   </Layout>
  )
}
