import React, { useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAddFaqCategoryMutation, useAddParentCategoryMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';

export default function CreateParentCategory() {

    const nofitySuccess = (message)=>toast.success(message)
    const notifyError = (message)=>toast.error(message)

    const [inputData,setInputData] = useState({});
    const [errorData,setErrorData] = useState({});
    const [imagePreview,setImagePreview] = useState('');

    const validateForm = ()=>{
        const errors = {}

        if (!inputData.title || inputData.title.trim() === '') errors.title = 'Title field is required';

        setErrorData(errors)

        return Object.keys(errors).length === 0;
    }


    const [AddParentCategoryMutation] = useAddParentCategoryMutation();

const token = getToken();

const handleApiError =  useApiErrorHandler();

const fileInputRef  = useRef(null);

const submitHandler = async (e)=>{
    e.preventDefault();
   if(validateForm()){
    const form_data = new FormData(e.currentTarget);
    const data = {
        title:form_data.get('title'),
        
    }
 
      const response = await AddParentCategoryMutation({token,data})

     

      if(response.error){
        handleApiError(response.error)
      }

      if(response.data.status){
        setInputData({title:''})

        // if(fileInputRef.current){
        //     fileInputRef.current.value = ''
        // }

        setImagePreview('')

        nofitySuccess(response.data.message)
        
      }else{
        if(response.data.errors){
            setErrorData(response.data.errors)
        }
        notifyError(response.data.message);
      }




   }
}

  return (
   <Layout>
     <div className='card'>
        <div className='card-header row'>
        <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Add Parent Category Form</h5>
        </div>
        <div className='col-md-6 text-end'>
            <Link to={`/admin/parent-category`} className='btn btn-danger'>Back</Link>
        </div>
        </div>
        <div className='card-body container'>
            <form className='container' onSubmit={submitHandler}>
            <ToastContainer />


            <div className='form-group'>
                    <label htmlFor='title'>Title *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,title:e.target.value})
                        setErrorData({...errorData,title:''})
                    }} className={`form-control ${errorData.title ? 'is-invalid':''}`} name='title' value={inputData.title} id='title' />
                {errorData.title && (
                    <div className='invalid-feedback'>{errorData.title}</div>
                )}
                </div>


                <div className=' my-3'>
                <input type='submit' className='btn btn-primary mr-3' style={{ marginRight:`5px` }} value={`Submit`} />
                <Link to={`/admin/parent-category`} className='btn btn-danger ml-3'>Back</Link>
        </div>
           
            </form>

      
      
        </div>
    </div>
   </Layout>
  )
}
