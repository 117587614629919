import React, { useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAddCaseStudiesMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useCkeditorUploadAdapter } from '../../../utils/ckeditorUploadAdapter';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

export default function CreateCaseStudies() {

    const nofitySuccess = (message)=>toast.success(message)
    const notifyError = (message)=>toast.error(message)

    const [inputData,setInputData] = useState({});
    const [errorData,setErrorData] = useState({});
    const [imagePreview,setImagePreview] = useState('');

    const [disableBtn,setDisableBtn] = useState(false)

    const validateForm = ()=>{
        const errors = {}

        if (!inputData.title || inputData.title.trim() === '') errors.title = 'Title field is required';
        if (!inputData.description || inputData.description.trim() === '') errors.description = 'Description field is required';
        if (!inputData.publish_date || inputData.publish_date.trim() === '') errors.publish_date = 'Publish Date field is required';
        if (!inputData.image) errors.image = 'Image field is required';

        setErrorData(errors)

        return Object.keys(errors).length === 0;
    }


    const [AddCaseStudiesMutation] = useAddCaseStudiesMutation();

const token = getToken();

const handleApiError =  useApiErrorHandler();

const fileInputRef  = useRef(null);

const submitHandler = async (e)=>{
    e.preventDefault();
   if(validateForm()){
    setDisableBtn(true)
    const form_data = new FormData(e.currentTarget);
    form_data.append('description', inputData.description);
      const AddCaseStudiesMutationResp = await AddCaseStudiesMutation({token,form_data})

      console.log(AddCaseStudiesMutationResp);

      if(AddCaseStudiesMutationResp.error){
        handleApiError(AddCaseStudiesMutationResp.error)
      }

      if(AddCaseStudiesMutationResp.data.status){
        setInputData({title:'',description:'',name:''})

        if(fileInputRef.current){
            fileInputRef.current.value = ''
        }

        setImagePreview('')

        nofitySuccess(AddCaseStudiesMutationResp.data.message)
        
      }else{
        if(AddCaseStudiesMutationResp.data.errors){
            setErrorData(AddCaseStudiesMutationResp.data.errors)
        }
        notifyError(AddCaseStudiesMutationResp.data.message);
      }

      setDisableBtn(false)


   }
}
    const {MyCustomUploadAdapterPlugin} = useCkeditorUploadAdapter();

const editorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'imageUpload'],
    extraPlugins: [MyCustomUploadAdapterPlugin],
};


  return (
   <Layout>
     <div className='card'>
        <div className='card-header row'>
        <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Add Case Studies Form</h5>
        </div>
        <div className='col-md-6 text-end'>
            <Link to={`/admin/case-studies`} className='btn btn-danger'>Back</Link>
        </div>
        </div>
        <div className='card-body container'>
            <form className='container' onSubmit={submitHandler}>
            <ToastContainer />


            <div className='form-group'>
                    <label htmlFor='title'>Title *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,title:e.target.value})
                        setErrorData({...errorData,title:''})
                    }} className={`form-control ${errorData.title ? 'is-invalid':''}`} name='title' value={inputData.title} id='title' />
                {errorData.title && (
                    <div className='invalid-feedback'>{errorData.title}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='description'>Description</label>
                  
                    <div className={`ck-editor-container ${errorData.error ? 'is-invalid':''}`}>
                    <CKEditor 
                        name="description"
                         id="description"
                         data={inputData.description}
                        editor={ClassicEditor}
                         onChange={(e,editor)=>{
                            const data = editor.getData();
                            setInputData(prevData=>({...prevData,description:data}))
                            setErrorData(prevData=>({...prevData,description:''}))
                            }
                        }
                        config={editorConfig}
                    />
                    </div>
                    {errorData.description && (
                        <div className='invalid-feedback d-block'>{errorData.description}</div>
                    )}
                    
                </div>

                <div className='form-group'>
    <label htmlFor='publish_date'>Publish Date *</label>
    <div className="date-group">
        <DatePicker
            selected={inputData.publish_date ? new Date(inputData.publish_date) : null}
            onChange={(date)=>{
                const formattedDate = format(date,'yyyy-MM-dd');
                setInputData({...inputData,publish_date:formattedDate})
                setErrorData({...errorData,publish_date:''})
            }}
            dateFormat='yyyy-MM-dd'
            className={`form-control ${errorData.publish_date ? 'is-invalid' : ''}`}
            name='publish_date'
            id='publish_date'
            calendarClassName="datepicker-calendar-left"
        />
    </div>
    {errorData.publish_date && (
        <div className='invalid-feedback d-block' >{errorData.publish_date}</div>
    )}
</div>


               

                <div className='form-group'>
                    <label htmlFor='image'>Image</label>
                    <input type='file' className={`form-control ${errorData.image?'is-invalid':''}`} onChange={(e)=>{
                        setInputData({...inputData,image:e.target.files[0]});
                        setImagePreview(URL.createObjectURL(e.target.files[0]))
                        setErrorData({...errorData,image:''})
                    }} name='image' id='image' />
                    {errorData.image && (
                        <div className='invalid-feedback'>{errorData.image}</div>
                    )}
                  {imagePreview && (
                    <img src={imagePreview} width={100}height={100}  />
                  )}
                </div>


             

                <div className=' my-3'>
                <input type='submit' disabled={disableBtn} className='btn btn-primary mr-3' style={{ marginRight:`5px` }} value={`Submit`} />
                <Link to={`/admin/case-studies`} className='btn btn-danger ml-3'>Back</Link>
        </div>
           
            </form>

      
      
        </div>
    </div>
   </Layout>
  )
}
