import React, { useEffect, useState } from 'react';
import Layout from '../../../master/Layout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {
  useHealthcareProfessionalDetailMutation,
  useUpdatePermissionhealthcareProfessionMutation,
} from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { format } from 'date-fns';

export default function HealthcareProfessionalsDetail() {
  const { id } = useParams();
  const [HealthcareProfessionalDetailMutation] = useHealthcareProfessionalDetailMutation();
  const [UpdatePermissionhealthcareProfessionMutation] = useUpdatePermissionhealthcareProfessionMutation();
  const [inputData, setInputData] = useState({});
  const [errorData, setErrorData] = useState({});
  const token = getToken();
  const navigate = useNavigate();


  const [permissions, setPermissions] = React.useState({
    ucoValuePermission: "false",
    surgeoryDiscountPermission: "false",
    consultantionFeePermission: "false",
    managePricingPermmision: "false"
  });


  // Function to fetch healthcare professional details
  const viewDetail = async (id) => {
    const editResponse = await HealthcareProfessionalDetailMutation({ token, id });
    if (editResponse?.data?.status) {
      setInputData(editResponse.data.data);
      setPermissions(editResponse.data.data.permission || {});
    }
  };

  useEffect(() => {
    viewDetail(id);
  }, [id]);

  // Handle checkbox changes for permissions
  const handleCheckboxChange = async (e, permissionKey) => {
    const { checked } = e.target;

    const updatedPermissions = {
      ...permissions,
      [permissionKey]: checked ? 'true' : 'false',
    };
    setPermissions(updatedPermissions);

    const form_data = {
      _method: 'PUT',
      permission: updatedPermissions,
    };

    const updateResponse = await UpdatePermissionhealthcareProfessionMutation({ id, token, form_data });
    if (updateResponse.data.status) {
      toast.success('Permissions updated successfully');
    } else {
      toast.error('Failed to update permissions');
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <div className='card'>
        <div className='card-header row'>
          <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>
              Healthcare Professional Detail
            </h5>
          </div>
          <div className='col-md-6 text-end'>
            <Link to='#' onClick={handleBack} className='btn btn-danger'>
              Back
            </Link>
          </div>
        </div>
        <div className='card-body container'>
          <form className='container' onSubmit={(e) => e.preventDefault()}>
            <ToastContainer />

            <div className='row'>
              {/* Input fields for healthcare professional details */}
              {['fullName', 'email', 'CPF', 'RG', 'phone', 'address','whatsAppNumber','zipCode', 'workPhone', 'workAddress'].map((field, index) => (
                <div className='col-md-6' key={index}>
                  <div className='form-group'>
                    <label htmlFor={field}>{`${field.charAt(0).toUpperCase() + field.slice(1)} *`}</label>
                    <input
                      type='text'
                      disabled
                      onChange={(e) => {
                        setInputData({ ...inputData, [field]: e.target.value });
                        setErrorData({ ...errorData, [field]: '' });
                      }}
                      className={`form-control ${errorData[field] ? 'is-invalid' : ''}`}
                      name={field}
                      value={inputData[field] || ''}
                      id={field}
                    />
                    {errorData[field] && <div className='invalid-feedback'>{errorData[field]}</div>}
                  </div>
                </div>
              ))}

              {inputData.type !== 'OtherHealthProfessional' && (
                <>
                <div className='col-md-6' >
                  <div className='form-group'>
                    <label htmlFor='CRM'>CRM</label>
                    <input
                      type='text'
                      disabled
                      onChange={(e) => {
                        setInputData({ ...inputData, CRM: e.target.value });
                        setErrorData({ ...errorData, CRM: '' });
                      }}
                      className={`form-control ${errorData.CRM ? 'is-invalid' : ''}`}
                      name='CRM'
                      value={inputData.CRM || ''}
                      id='CRM'
                    />
                    {errorData.CRM && <div className='invalid-feedback'>{errorData.CRM}</div>}
                  </div>
                </div>

                      
               
                  


                </>
              )}

              {inputData.type === 'Surgeon' && (
                <>

                

                <div className='col-md-6' >
                  <div className='form-group'>
                    <label htmlFor='RQE'>RQE</label>
                    <input
                      type='text'
                      disabled
                      onChange={(e) => {
                        setInputData({ ...inputData, RQE: e.target.value });
                        setErrorData({ ...errorData, RQE: '' });
                      }}
                      className={`form-control ${errorData.RQE ? 'is-invalid' : ''}`}
                      name='RQE'
                      value={inputData.RQE || ''}
                      id='RQE'
                    />
                    {errorData.RQE && <div className='invalid-feedback'>{errorData.RQE}</div>}
                  </div>
                </div>

                      
               
                  


                </>
              )}


              { inputData.type === 'OtherHealthProfessional' && (
                <>

                <div className='col-md-6' >
                  <div className='form-group'>
                    <label htmlFor='profession'>Profession</label>
                    <input
                      type='text'
                      disabled
                      onChange={(e) => {
                        setInputData({ ...inputData, profession: e.target.value });
                        setErrorData({ ...errorData, profession: '' });
                      }}
                      className={`form-control ${errorData.profession ? 'is-invalid' : ''}`}
                      name='profession'
                      value={inputData.profession || ''}
                      id='profession'
                    />
                    {errorData.profession && <div className='invalid-feedback'>{errorData.profession}</div>}
                  </div>
                </div>
                
                <div className='col-md-6' >
                  <div className='form-group'>
                    <label htmlFor='councilState'>Council State</label>
                    <input
                      type='text'
                      disabled
                      onChange={(e) => {
                        setInputData({ ...inputData, councilState: e.target.value });
                        setErrorData({ ...errorData, councilState: '' });
                      }}
                      className={`form-control ${errorData.councilState ? 'is-invalid' : ''}`}
                      name='councilState'
                      value={inputData.councilState || ''}
                      id='councilState'
                    />
                    {errorData.councilState && <div className='invalid-feedback'>{errorData.councilState}</div>}
                  </div>
                </div>
                </>
                )}

              {inputData.type !== 'FreelanceAnesthesiologist' && 
              inputData.type !== 'OtherHealthProfessional' && (
                <>

                {inputData.type !== 'GeneralPractitioner' && inputData.type  !== 'SpecialistClinician' &&  (
                  <>

                <div className='col-md-6' >
                  <div className='form-group'>
                    <label htmlFor='anesthesiologists'>Linked Anesthesiologists1</label>
                    <input
                      type='text'
                      disabled
                      onChange={(e) => {
                        setInputData({ ...inputData, anesthesiologists: e.target.value });
                        setErrorData({ ...errorData, anesthesiologists: '' });
                      }}
                      className={`form-control ${errorData.anesthesiologists ? 'is-invalid' : ''}`}
                      name='anesthesiologists'
                      value={inputData.anesthesiologists?.companyFantasyName
                        || inputData.anesthesiologists?.fullName}
                      id='anesthesiologists'
                    />
                    {errorData.anesthesiologists && <div className='invalid-feedback'>{errorData.anesthesiologists}</div>}
                  </div>
                </div>


                 <div className='col-md-6' >
                  <div className='form-group'>
                    <label htmlFor='pathologist'>Linked Pathology Clinics</label>
                    <input
                      type='text'
                      disabled
                      onChange={(e) => {
                        setInputData({ ...inputData, pathologist: e.target.value });
                        setErrorData({ ...errorData, pathologist: '' });
                      }}
                      className={`form-control ${errorData.pathologist ? 'is-invalid' : ''}`}
                      name='pathologist'
                      value={inputData.pathologist?.companyFantasyName
                        || ''}
                      id='pathologist'
                    />
                    {errorData.pathologist && <div className='invalid-feedback'>{errorData.pathologist}</div>}
                  </div>
                </div> 

                 <div className='col-md-6' >
                  <div className='form-group'>
                    <label htmlFor='specialMaterialCompany'>Linked Special Materials</label>
                    <input
                      type='text'
                      disabled
                      onChange={(e) => {
                        setInputData({ ...inputData, specialMaterialCompany: e.target.value });
                        setErrorData({ ...errorData, specialMaterialCompany: '' });
                      }}
                      className={`form-control ${errorData.specialMaterialCompany ? 'is-invalid' : ''}`}
                      name='specialMaterialCompany'
                      value={inputData.specialMaterialCompany?.companyFantasyName
                        || ''}
                      id='specialMaterialCompany'
                    />
                    {errorData.specialMaterialCompany && <div className='invalid-feedback'>{errorData.specialMaterialCompany}</div>}
                  </div>
                </div>

                </>

                )}

               
                  <div className='col-md-6' >
                  <div className='form-group'>
                    <label htmlFor='customHospitalName'>Linked Hospital</label>
                    <input
                      type='text'
                      disabled
                      onChange={(e) => {
                        setInputData({ ...inputData, customHospitalName: e.target.value });
                        setErrorData({ ...errorData, customHospitalName: '' });
                      }}
                      className={`form-control ${errorData.customHospitalName ? 'is-invalid' : ''}`}
                      name='customHospitalName'
                      value={inputData.customHospitalName !== '' ? inputData.customHospitalName
                        : ''}
                      id='customHospitalName'
                    />
                    {errorData.customHospitalName && <div className='invalid-feedback'>{errorData.customHospitalName}</div>}
                  </div>
                </div>
                </>
              )}
         

             
                <div className='col-md-6' >
                  <div className='form-group'>
                    <label htmlFor='dob'>Date of Birth</label>
                    <input
                      type='text'
                      disabled
                      onChange={(e) => {
                        setInputData({ ...inputData, dob: e.target.value });
                        setErrorData({ ...errorData, dob: '' });
                      }}
                      className={`form-control ${errorData.dob ? 'is-invalid' : ''}`}
                      name='dob'
                      value={inputData?.dob ? format(new Date(inputData.dob), 'dd/MM/yyyy') : ''}
                      id='dob'
                    />
                    {errorData.dob && <div className='invalid-feedback'>{errorData.dob}</div>}
                  </div>
                </div>
               


              {/* Social Media Links */}
              <div className='form-group'>
                <label htmlFor='technicalManagerCRM'>Social Links *</label>
                <div style={{ marginTop: '10px' }}>
                  {inputData.socialMedia &&
                    inputData.socialMedia.map((value, index) => (
                      <React.Fragment key={index}>
                        <div className='row' style={{ display: 'flex' }}>
                          <div className='col-md-6'>
                            <label>Facebook Link</label>
                            <input className='form-control' disabled value={value.facebookLink} />
                          </div>
                          <div className='col-md-6'>
                            <label>Instagram Link</label>
                            <input className='form-control' disabled value={value.instagramLink} />
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                </div>
              </div>

              {/* Cities of Operation */}
              <div className='form-group'>
                <label htmlFor='citiesOfOperation'>Cities of Operation *</label>
                <input
                  type='text'
                  disabled
                  onChange={(e) => {
                    setInputData({ ...inputData, citiesOfOperation: e.target.value });
                    setErrorData({ ...errorData, citiesOfOperation: '' });
                  }}
                  className={`form-control ${errorData.citiesOfOperation ? 'is-invalid' : ''}`}
                  name='citiesOfOperation'
                  value={inputData?.citiesOfOperation && inputData.cities.map((value) => value.name).join(',')}
                  id='citiesOfOperation'
                />
                {errorData.citiesOfOperation && <div className='invalid-feedback'>{errorData.citiesOfOperation}</div>}
              </div>
            </div>


            {/* Permissions Section */}
            {inputData.type === 'GeneralPractitioner' && (
<>
<div className='card mt-5'>
              <div className='card-header'>
                <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>
                  Permission
                </h5>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-6'>
                  {inputData.type === 'Surgeon' && (
                    <>
                    <ul style={{ listStyle: 'none' }}>
                      {[
                        { key: 'ucoValuePermission', label: 'UCO Value Permission' },
                        { key: 'surgeoryDiscountPermission', label: 'Surgery Discount Permission' },
                        { key: 'consultantionFeePermission', label: 'Consultation Fees Permission' },
                        { key: 'managePricingPermmision', label: 'Manage Pricing Permission' },
                      ].map((permission, index) => (
                        <li key={index}>
                          <input
                            type='checkbox'
                            checked={permissions[permission?.key] === 'true'}
                            onChange={(e) => handleCheckboxChange(e, permission.key)}
                          />
                          <span> {permission.label}</span>
                        </li>
                      ))}
                    </ul>
                    </>
                  )}

                  {inputData.type === 'FreelanceAnesthesiologist' && (
                    <>
                    <ul style={{ listStyle: 'none' }}>
                      {[
                        { key: 'managePricingPermmision', label: 'Manage Pricing Permission' },
                      ].map((permission, index) => (
                        <li key={index}>
                          <input
                            type='checkbox'
                            checked={permissions[permission?.key] === 'true'}
                            onChange={(e) => handleCheckboxChange(e, permission.key)}
                          />
                          <span> {permission.label}</span>
                        </li>
                      ))}
                    </ul>
                    </>
                  )}
                   
                  </div>
                </div>
              </div>
            </div>
</>
            )}
           

            <div className='my-3'>
              <Link to='#' onClick={handleBack} className='btn btn-danger ml-3'>
                Back
              </Link>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
