import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAddTestimonialMutation, useEditTestimonialMutation, useUpdateTestimonialMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { BASEURL } from '../../../Config';

export default function EditTestimonial() {

const {id} = useParams()

const [EditTestimonialMutation] =  useEditTestimonialMutation();

const viewDetail = async(id)=>{
  const editResponse =   await EditTestimonialMutation({token,id})

  if(editResponse.data.status){
    setInputData(editResponse.data.data)

    setImagePreview(BASEURL+''+editResponse.data.data.image)
  }
}

useEffect(()=>{
    viewDetail(id);
},[])


    const nofitySuccess = (message)=>toast.success(message)
    const notifyError = (message)=>toast.error(message)

    const [inputData,setInputData] = useState({});
    const [errorData,setErrorData] = useState({});
    const [imagePreview,setImagePreview] = useState('');

    const validateForm = ()=>{
        const errors = {}

        if (!inputData.title || inputData.title.trim() === '') errors.title = 'Title field is required';
        if (!inputData.description || inputData.description.trim() === '') errors.description = 'Description field is required';
        if (!inputData.name || inputData.name.trim() === '') errors.name = 'Name field is required';
        if (!inputData.image) errors.image = 'Image field is required';

        setErrorData(errors)

        return Object.keys(errors).length === 0;
    }


    const [UpdateTestimonialMutation] = useUpdateTestimonialMutation();

const token = getToken();

const handleApiError =  useApiErrorHandler();

const fileInputRef  = useRef(null);

const updateHandler = async (e)=>{
    e.preventDefault();
   if(validateForm()){
    const form_data = new FormData(e.currentTarget);

    form_data.append('_id',id)
    form_data.append('_method','PUT')

      const updateResponse = await UpdateTestimonialMutation({id,token,form_data})

      console.log(updateResponse);

      if(updateResponse.error){
        handleApiError(updateResponse.error)
      }

      if(updateResponse.data.status){
        // setInputData({title:'',description:'',name:''})

        // if(fileInputRef.current){
        //     fileInputRef.current.value = ''
        // }

        // setImagePreview('')

        nofitySuccess(updateResponse.data.message)
        
      }else{
        if(updateResponse.data.errors){
            setErrorData(updateResponse.data.errors)
        }
        notifyError(updateResponse.data.message);
      }

   }
}



  return (
   <Layout>
     <div className='card'>
        <div className='card-header row'>
        <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Edit Testimonail Form</h5>
        </div>
        <div className='col-md-6 text-end'>
            <Link to={`/admin/testimonials`} className='btn btn-danger'>Back</Link>
        </div>
        </div>
        <div className='card-body container'>
            <form className='container' onSubmit={updateHandler}>
            <ToastContainer />


            <div className='form-group'>
                    <label htmlFor='title'>Title *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,title:e.target.value})
                        setErrorData({...errorData,title:''})
                    }} className={`form-control ${errorData.title ? 'is-invalid':''}`} name='title' value={inputData.title} id='title' />
                {errorData.title && (
                    <div className='invalid-feedback'>{errorData.title}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='description'>Description</label>
                    <textarea className={`form-control ${errorData.description ?'is-invalid':''}`} name='description' onChange={(e)=>{
                        setInputData({...inputData,description:e.target.value})
                        setErrorData({...errorData,description:''})
                    }} id='description' value={inputData.description} ></textarea>
                    {errorData.description && (
                        <div className='invalid-feedback'>{errorData.description}</div>
                    )}
                </div>

                <div className='form-group'>
                    <label htmlFor='name'>Client Name *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,name:e.target.value})
                        setErrorData({...errorData,name:''})
                    }} className={`form-control ${errorData.name ? 'is-invalid':''}`} name='name' value={inputData.name} id='name' />
                {errorData.name && (
                    <div className='invalid-feedback'>{errorData.name}</div>
                )}
                </div>

               

                <div className='form-group'>
                    <label htmlFor='image'>Image</label>
                    <input type='file' className={`form-control ${errorData.image?'is-invalid':''}`} onChange={(e)=>{
                        setInputData({...inputData,image:e.target.files[0]});
                        setImagePreview(URL.createObjectURL(e.target.files[0]))
                        setErrorData({...errorData,image:''})
                    }} name='image' id='image' />
                    {errorData.image && (
                        <div className='invalid-feedback'>{errorData.image}</div>
                    )}
                  {imagePreview && (
                    <img src={imagePreview} width={100}height={100}  />
                  )}
                </div>


             

                <div className=' my-3'>
                <input type='submit' className='btn btn-primary mr-3' style={{ marginRight:`5px` }} value={`Submit`} />
                <Link to={`/admin/testimonials`} className='btn btn-danger ml-3'>Back</Link>
        </div>
           
            </form>

      
      
        </div>
    </div>
   </Layout>
  )
}
