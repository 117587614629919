import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAddFaqMutation, useFaqCategoryListMutation, useUploadImageMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './createFAQ.css';
import { useCkeditorUploadAdapter } from '../../../utils/ckeditorUploadAdapter';

export default function CreateFAQ() {

      const {MyCustomUploadAdapterPlugin} = useCkeditorUploadAdapter();

    const nofitySuccess = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message);

    const [inputData, setInputData] = useState({});
    const [errorData, setErrorData] = useState({});
    const [faqCategoryList, setFaqCategoryList] = useState([]);

    const validateForm = () => {
        const errors = {};

        if (!inputData.question || inputData.question.trim() === '') errors.question = 'Question field is required';
        if (!inputData.answer || inputData.answer.trim() === '') errors.answer = 'Answer field is required';
        if (!inputData.faqCategoryId || inputData.faqCategoryId.trim() === '') errors.faqCategoryId = 'Faq Category Id field is required';

        setErrorData(errors);
        return Object.keys(errors).length === 0;
    }

    const [AddFaqMutation] = useAddFaqMutation();
    const token = getToken();
    const handleApiError = useApiErrorHandler();

    const submitHandler = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const data = {
                question: inputData.question,
                answer: inputData.answer,
                faqCategoryId: inputData.faqCategoryId,
            };

            const response = await AddFaqMutation({ token, data });

            if (response.error) {
                handleApiError(response.error);
            }

            if (response.data.status) {
                setInputData({ question: '', answer: '', faqCategoryId: '' });
                nofitySuccess(response.data.message);
            } else {
                if (response.data.errors) {
                    setErrorData(response.data.errors);
                }
                notifyError(response.data.message);
            }
        }
    }

    const [FaqCategoryListMutation] = useFaqCategoryListMutation();

    const getFaqCategoryList = async () => {
        const FaqCategoryListMutationResp = await FaqCategoryListMutation({ token, status: 'active' });
        if (FaqCategoryListMutationResp.data.status) {
            setFaqCategoryList(FaqCategoryListMutationResp.data.data);
        }
    }

    useEffect(() => {
        getFaqCategoryList();
    }, []);

    const editorConfig = {
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'imageUpload'],
        extraPlugins: [MyCustomUploadAdapterPlugin],
    };



    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setInputData({ ...inputData, answer: data });
        setErrorData({ ...errorData, answer: '' });
    };

    return (
        <Layout>
            <div className='card'>
                <div className='card-header row'>
                    <div className='col-md-6 '>
                        <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Add FAQ Form</h5>
                    </div>
                    <div className='col-md-6 text-end'>
                        <Link to={`/admin/FAQ`} className='btn btn-danger'>Back</Link>
                    </div>
                </div>
                <div className='card-body container'>
                    <form className='container' onSubmit={submitHandler}>
                        <ToastContainer />

                        <div className='form-group'>
                            <label htmlFor='question'>Question *</label>
                            <input
                                type='text'
                                onChange={(e) => {
                                    setInputData({...inputData,question:e.target.value});
                                    setErrorData({...errorData,question:''});
                                }}
                                className={`form-control ${errorData.question ? 'is-invalid' : ''}`}
                                name='question'
                                value={inputData.question}
                                id='question'
                            />
                            {errorData.question && (
                                <div className='invalid-feedback'>{errorData.question}</div>
                            )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='answer'>Answer *</label>
                            <div className={`ck-editor-container ${errorData.answer ? 'is-invalid' : ''}`}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={inputData.answer}
                                    name='answer'
                                    id='answer'
                                    onChange={(e, editor) => {
                                        const data = editor.getData();
                                        setInputData(prevData => ({
                                            ...prevData,
                                            answer: data
                                        }));
                                        setErrorData(prevErrors => ({
                                            ...prevErrors,
                                            answer: ''
                                        }));
                                       
                                    }}
                                    // onChange={handleEditorChange}
                                    config={editorConfig}
                                />
                            </div>
                            {errorData.answer && (
                                <div className='invalid-feedback d-block'>{errorData.answer}</div>
                            )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='faqCategoryId'>Faq Category Id*</label>
                            <select
                                name='faqCategoryId'
                                id='faqCategoryId'
                                className={`form-control ${errorData.faqCategoryId ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  
                                    setInputData({...inputData,faqCategoryId:e.target.value});
                                    setErrorData({...errorData,faqCategoryId:''});
                               
                                }}
                                value={inputData.faqCategoryId}
                            >
                                <option value="">--Please Select--</option>
                                {faqCategoryList.length > 0 && faqCategoryList.map((value, index) => (
                                    <option key={index} value={value._id}>{value.title}</option>
                                ))}
                            </select>
                            {errorData.faqCategoryId && (
                                <div className='invalid-feedback'>{errorData.faqCategoryId}</div>
                            )}
                        </div>

                        <div className='my-3'>
                            <input type='submit' className='btn btn-primary mr-3' style={{ marginRight: `5px` }} value={`Submit`} />
                            <Link to={`/admin/FAQ`} className='btn btn-danger ml-3'>Back</Link>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}
