import React, { useEffect, useState } from 'react'
import Layout from '../../../master/Layout'
import { toast, ToastContainer } from 'react-toastify';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { Link } from 'react-router-dom';
import { BASEURL } from '../../../Config';

export default function Review() {

    const notifyError = (message)=>toast.error(message);
    const notifySuccuess = (message)=>toast.success(message);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = getToken();

    const [list,setList] = useState([]);
    const [searchInput,setSearchInput] = useState({keyword:'',status:''});

    const handleApiError = useApiErrorHandler();

    // const [categoryListMutation] = useCategoryListMutation();
    // const [deleteCategoryMutation] = useDeleteCategoryMutation();
    // const [ChangeStatusMutation] = useChangeStatusMutation();


    const getList = async ()=>{
        try{
        // const userListMutationResp = await categoryListMutation({token,keyword:searchInput.keyword,status:searchInput.status,page:currentPage});
       
        // console.log(userListMutationResp.error.data.statusCode)
    //   if(userListMutationResp.error){

    //     handleApiError(userListMutationResp.error)
    //   }
       
        // if(userListMutationResp.data.status){
        //     setList(userListMutationResp.data.data);
        //     setTotalPages(userListMutationResp.data.pagination.last_page);
        // }
    } catch (error) {
        // console.log('vikas')
        // if (error?.statusCode === 403) {
        // console.log(error)

            // toast.error('You are not authorized to perform this action.');
            // removeToken();
            // navigate('/')
        // }
    }
    }

    useEffect(()=>{
        getList();
    },[]);

    useEffect(()=>{
        const delayFun = setTimeout(()=>{
            getList();
        },0);
        return () => clearTimeout(delayFun);
    },[searchInput, currentPage]);

    const deleteData = async (id)=>{
        if(window.confirm('Are you sure you want to delete this ?')){
            // const deleteUserMutationResp = await deleteCategoryMutation({token,id});
            // if(deleteCategoryMutationResp.data.status){
                getList();
                // notifySuccuess(deleteUserMutationResp.data.message);
            // } else {
                // notifyError(deleteUserMutationResp.data.message);
            // }
        }
    }

    const changeStatus = async (table_name, id, status)=>{
        const form_data = {table_name, id, status};
        const ChangeStatusMutationResp = await ChangeStatusMutationResp({token, form_data});
        if(ChangeStatusMutationResp.data.status){
            notifySuccuess(ChangeStatusMutationResp.data.message);
            getList();
        } else {
            notifyError(ChangeStatusMutationResp.data.message);
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };




  return (
    <Layout>
    <ToastContainer />
    <div className="card">
        <div className="card-header">
            <div className="row">
                <div className="col-md-6 mt-2 pagetitle">
                    <h3>Review List</h3> 
                </div>
                <div className="col-md-6 text-end mt-3">
                    <Link className="btn btn-primary" to={`/admin/create-user`}>Add Review</Link>
                </div>
            </div>
        </div>
        <div className="card-body ">
            <div className="row mb-3">
                <div className="col-md-3">
                    <input 
                        type='text' 
                        className='form-control' 
                        name='search' 
                        id='search' 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, keyword: e.target.value})
                        }} 
                        value={searchInput.keyword} 
                        placeholder='Search here..' 
                    />
                </div>
                <div className="col-md-3">
                    <select 
                        name='status' 
                        value={searchInput.status} 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, status: e.target.value})
                        }} 
                        className='form-control'
                    >
                        <option value=''>--Please select--</option>
                        <option value='active'>Active</option>
                        <option value='inactive'>Inactive</option>
                    </select>
                </div>
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Image</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    list.length > 0 ? list.map((value, index)=>(
                        <tr key={index}>
                            {/* <td>{(currentPage - 1) * 3 + index + 1}</td> */}
                            <td>{index + 1}</td>
                            <td><img src={`${BASEURL}${value.image}`} width="50px" height="50px" alt="User" /></td>
                            <td>{value.name}</td>
                            <td>{value.email}</td>
                            <td>{value.status === 'active' ? (
                                <Link onClick={()=>{ changeStatus('users', value._id, 'inactive') }} className='btn btn-success text-white'>Active</Link>
                            ):(
                                <Link onClick={()=>{ changeStatus('users', value._id, 'active') }} className='btn btn-danger text-white'>Inactive</Link>
                            )}</td>
                            <td>
                                <Link to={`/admin/user/${value._id}/edit`} className='btn btn-warning btn-sm mx-1'><i className='bi bi-pencil'></i></Link>
                                <Link onClick={()=>{ deleteData(value._id) }} className='btn btn-danger btn-sm mx-1'><i className="bi bi-trash"></i></Link>
                            </td>
                        </tr>
                    )):(
                        <tr>
                            <td colSpan="6" className='text-center'>No Record Found</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            <div className="d-flex justify-content-center text-end">
                <nav>
               { list.length > 0 &&
                    <ul className="pagination text-end">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
               }
                </nav>
            </div>
        </div>
    </div>
</Layout>
  )
}
