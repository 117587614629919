import React, { useEffect, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link } from 'react-router-dom'
import { object } from 'yup'
import { toast, ToastContainer } from 'react-toastify'
import { useAdminProfileMutation, useUpdateAdminProfileMutation } from '../../../services/ProjectApi'
import { getToken } from '../../../services/Token'
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler'
import { setIn } from 'formik'
import { BASEURL } from '../../../Config'
import { useDispatch } from 'react-redux'
import { setUserInfo } from '../../../fetures/userSlice'

export default function Profile() {

  const nofitySuccess = (message)=>toast.success(message);
  const notifyError = (message=>toast.error(message));

  const dispatch = useDispatch();

  const [errorData,setErrorData] = useState({})
  const [inputData,setInputData] = useState({})

  const [imagePreview ,setImagePreview]  = useState('');

  const [UpdateAdminProfileMutation] = useUpdateAdminProfileMutation()


  const validatForm = ()=>{
    const errors = {};

    if(!inputData.name) errors.name = 'Name field is required';
    if(!inputData.email) errors.email = 'Email field is required';
    if(!inputData.phone) errors.phone = 'Phone field is required';
    if(!inputData.dob) errors.dob = 'Date of Birth field is required';
    if(!inputData.profile) errors.profile = 'Profile field is required';

   setErrorData(errors);

   return Object.keys(errors).length === 0;
  }


  const submitHandler = async (e)=>{
    e.preventDefault();

    if(validatForm()){
      try{
      const form_data = new FormData(e.currentTarget);

      const UpdateAdminProfileResp = await UpdateAdminProfileMutation({token,form_data})
      // console.log(UpdateAdminProfileMutation)

      if(UpdateAdminProfileResp.data.status){

        dispatch(setUserInfo(UpdateAdminProfileResp.data.data))

        nofitySuccess(UpdateAdminProfileResp.data.message)
      }else{
        if(UpdateAdminProfileResp.data.errors){
          setErrorData(UpdateAdminProfileResp.data.errors)
        }
        else{
          notifyError(UpdateAdminProfileResp.data.message)
        }
      }
    }
    catch(error){
      notifyError(error.message)
    }
  }
  }

const [AdminProfile] = useAdminProfileMutation();
const token  = getToken();
const handleApiError = useApiErrorHandler();

const currentYear = new Date().getFullYear();

const getAdminProfileDetail = async (year=currentYear)=>{
  try{
    const AdminProfileMutationResp =  await AdminProfile({year,token})

    if(AdminProfileMutationResp.error){
      handleApiError(AdminProfileMutationResp.error);
    }

    if(AdminProfileMutationResp.data.status){
      setInputData(AdminProfileMutationResp.data.data)

      setImagePreview(BASEURL+''+AdminProfileMutationResp.data.data.profile)
    }

  }catch(error){
    notifyError(error.message)
  }
}

  useEffect(()=>{
    getAdminProfileDetail();
  },[])
  return (
   <Layout>
   <ToastContainer />
    <div className='card'>
      <div className='card-header'>
        <div className='row'>
          <div className='col-md-6'>
            <h5 className='pagetitle mt-3'>Edit your profile</h5>
          </div>
          <div className='col-md-6 text-end'>
            <Link to={`/admin/dashboard`} className='btn btn-danger mt-2'>Dashboard</Link>
          </div>
        </div>
      </div>
      <div className='card-body'>
        <form onSubmit={submitHandler}>
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input type='text' className={`form-control ${errorData.name ? 'is-invalid':''}`} value={inputData.name||''}
            onChange={(e)=>{ 
              setInputData({...inputData,name:e.target.value})
             setErrorData({...errorData,name:''})
            }}
             name='name' id='name' />
            {errorData && (
              <div className='invalid-feedback'>{errorData.name}</div>
            )}
          </div>

          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input type='text' className={`form-control ${errorData.email ? 'is-invalid':''}`} value={inputData.email||''}
            onChange={(e)=>{ 
              setInputData({...inputData,email:e.target.value})
             setErrorData({...errorData,email:''})
            }}
             name='email' id='email' />
            {errorData && (
              <div className='invalid-feedback'>{errorData.email}</div>
            )}
          </div>

          <div className='form-group'>
            <label htmlFor='phone'>Phone</label>
            <input type='text' className={`form-control ${errorData.phone ? 'is-invalid':''}`} value={inputData.phone||''}
            onChange={(e)=>{ 
              setInputData({...inputData,phone:e.target.value})
             setErrorData({...errorData,phone:''})
            }}
             name='phone' id='phone' />
            {errorData && (
              <div className='invalid-feedback'>{errorData.phone}</div>
            )}
          </div>

          <div className='form-group'>
            <label htmlFor='dob'>Date of Birth</label>
            <input type='date' className={`form-control ${errorData.dob ? 'is-invalid':''}`} value={inputData.dob||''}
            onChange={(e)=>{ 
              setInputData({...inputData,dob:e.target.value})
             setErrorData({...errorData,dob:''})
            }}
             name='dob' id='dob' />
            {errorData && (
              <div className='invalid-feedback'>{errorData.dob}</div>
            )}
          </div>

          <div className='form-group'>
          <label htmlFor='profile'>Profile</label>
          <input type='file' name='profile' id='profile' className={`form-control ${errorData.profile ? 'is-invalid':''}`}
          onChange={(e)=>{
            setInputData({...inputData,profile:e.target.files[0]});
            setErrorData({...errorData,profile:''})
            setImagePreview(URL.createObjectURL(e.target.files[0]))
          }}
           />
            {errorData.profile && (
              <div className='invalid-feedback'>{errorData.profile}</div>
            )}
            {imagePreview && (
              <div className='col-md-12 mt-3'>
                <img src={imagePreview} width={`100`} height={`100`} />
              </div>
            )}
          </div>
          <div className='form-group'>
            <input type='submit' className='btn btn-primary' value={`Update`} />
          </div>
        </form>
      </div>
    </div>
   </Layout>
  )
}
