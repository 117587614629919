import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useEditFaqCategoryMutation, useEditPriceAnesthesiologistMutation, useEditPriceImageDiagnosticMutation, useEditPriceLaboratoryMutation, useEditPriceSurgeonMutation, useUpdateFaqCategoryMutation, useUpdatePriceAnesthesiologistMutation, useUpdatePriceImageDiagnosticMutation, useUpdatePriceLaboratoryMutation, useUpdatePriceSurgeonMutation } from '../../../../services/ProjectApi';
import { BASEURL } from '../../../../Config';
import { getToken } from '../../../../services/Token';
import { useApiErrorHandler } from '../../../../utils/useApiErrorHandler';
import Layout from '../../../../master/Layout';
import { formDataToJson } from '../../../../services/utility';

export default function EditPriceImageDiagnostic() {

const {id} = useParams()

const [EditPriceImageDiagnosticMutation] =  useEditPriceImageDiagnosticMutation();

const viewDetail = async(id)=>{
  const editResponse =   await EditPriceImageDiagnosticMutation({token,id})

  if(editResponse.data.status){
    setInputData(editResponse.data.data)

    setImagePreview(BASEURL+''+editResponse.data.data.image)
  }
}

useEffect(()=>{
    viewDetail(id);
},[])


    const nofitySuccess = (message)=>toast.success(message)
    const notifyError = (message)=>toast.error(message)

    const [inputData,setInputData] = useState({});
    const [errorData,setErrorData] = useState({});
    const [imagePreview,setImagePreview] = useState('');

    const validateForm = ()=>{
        const errors = {}

        if (!inputData.complexity || inputData.complexity.trim() === '') errors.complexity = 'Image Diagnostic Complexity field is required';
        if (!inputData.price || inputData.price.trim() === '') errors.price = 'Price field is required';

        setErrorData(errors)
        return Object.keys(errors).length === 0;
    }


    const [UpdatePriceImageDiagnosticMutation] = useUpdatePriceImageDiagnosticMutation();

const token = getToken();

const handleApiError =  useApiErrorHandler();

const fileInputRef  = useRef(null);

const updateHandler = async (e)=>{
    e.preventDefault();
   if(validateForm()){
    const form_data = new FormData(e.currentTarget);

    form_data.append('_id',id)
    form_data.append('_method','PUT')

     const data = formDataToJson(form_data)

    // const data = {
    //     '_id':id,'_method':'PUT','title':form_data.get('title')
    // }

      const updateResponse = await UpdatePriceImageDiagnosticMutation({id,token,data})

    //   console.log(updateResponse);

      if(updateResponse.error){
        handleApiError(updateResponse.error)
      }

      if(updateResponse.data.status){
        // setInputData({title:''})

        // if(fileInputRef.current){
        //     fileInputRef.current.value = ''
        // }

        setImagePreview('')

        nofitySuccess(updateResponse.data.message)
        
      }else{
        if(updateResponse.data.errors){
            setErrorData(updateResponse.data.errors)
        }
        notifyError(updateResponse.data.message);
      }

   }
}



  return (
   <Layout>
     <div className='card'>
        <div className='card-header row'>
        <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Edit Price Image Diagnostic Form</h5>
        </div>
        <div className='col-md-6 text-end'>
            <Link to={`/admin/price-management-laboratory`} className='btn btn-danger'>Back</Link>
        </div>
        </div>
        <div className='card-body container'>
            <form className='container' onSubmit={updateHandler}>
            <ToastContainer />


            <div className='form-group'>
                    <label htmlFor='complexity'>Complexity *</label>
                    <input type='text'
                  
                    onChange={(e) => {
                        const { value } = e.target;
                     
                        // const numericValue = value.replace(/\D/g, "");
                        setInputData({ ...inputData, complexity: value });

                        // if (value !== numericValue) {
                        //   setErrorData({
                        //     ...errorData,
                        //     complexity: "Please enter only digits",
                        //   });
                        // } else {
                          setErrorData({ ...errorData, complexity: "" });
                        // }
                      }}
                    
                    
                    className={`form-control ${errorData.complexity ? 'is-invalid':''}`} name='complexity' value={inputData.complexity} id='complexity' />
                {errorData.complexity && (
                    <div className='invalid-feedback'>{errorData.complexity}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='price'>Price *</label>
                    <input type='text'
                       onChange={(e) => {
                        const { value } = e.target;
                     
                        // const numericValue = value.replace(/\D/g, "");
                        setInputData({ ...inputData, price: value });

                        // if (value !== numericValue) {
                        //   setErrorData({
                        //     ...errorData,
                        //     price: "Please enter only digits",
                        //   });
                        // } else {
                          setErrorData({ ...errorData, price: "" });
                        // }
                      }}
                    
                    className={`form-control ${errorData.price ? 'is-invalid':''}`} name='price' value={inputData.price} id='price' />
                {errorData.price && (
                    <div className='invalid-feedback'>{errorData.price}</div>
                )}
                </div>

              
             

                <div className=' my-3'>
                <input type='submit' className='btn btn-primary mr-3' style={{ marginRight:`5px` }} value={`Submit`} />
                <Link to={`/admin/price-management-image-diagnostic`} className='btn btn-danger ml-3'>Back</Link>
        </div>
           
            </form>

      
      
        </div>
    </div>
   </Layout>
  )
}
