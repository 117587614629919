import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { toast, ToastContainer } from 'react-toastify';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import {  Link } from 'react-router-dom';
import { useCbhpmListMutation, useChangeStatusMutation, useDeleteCbhpmMutation, useDeleteFaqCategoryMutation, useFaqCategoryListMutation, useUploadCbhpmFileMutation } from '../../../services/ProjectApi';

export default function Cbhpm() {

    const notifyError = (message)=>toast.error(message);
    const notifySuccuess = (message)=>toast.success(message);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [list,setList] = useState([]);
    const [searchInput,setSearchInput] = useState({keyword:'',status:''});

    const token = getToken();

   

    const handleApiError = useApiErrorHandler();

    const [FaqCategoryListMutation] = useCbhpmListMutation();
    const [DeleteCbhpmMutation] = useDeleteCbhpmMutation();
    const [ChangeStatusMutation] = useChangeStatusMutation();


    const getList = async ()=>{
        try{
        const response = await FaqCategoryListMutation({token,keyword:searchInput.keyword,status:searchInput.status,page:currentPage});
       
      if(response.error){

        handleApiError(response.error)
      }
        if(response.data.status){
            setList(response.data.data);
            setTotalPages(response.data.pagination.last_page);
        }
    } catch (error) {
        console.log(error)
        notifyError("An unexpected error occurred.");
    }
    }

    useEffect(()=>{
        getList();
    },[currentPage]);

    useEffect(()=>{
        const delayFun = setTimeout(()=>{
            getList();
        },0);
        return () => clearTimeout(delayFun);
    },[searchInput]);

    const deleteData = async (id)=>{
        if(window.confirm('Are you sure you want to delete this ?')){
            const deleteResponse = await DeleteCbhpmMutation({token,id});
            if(deleteResponse.data.status){
              console.log(deleteResponse.data)
              notifySuccuess(deleteResponse.data.message);
                getList();
            } else {
                notifyError(deleteResponse.data.message);
            }
        }
    }

    const changeStatus = async (table_name, id, status)=>{
        const form_data = {table_name, id, status};
        const ChangeStatusMutationResp = await ChangeStatusMutation({token, form_data});
        if(ChangeStatusMutationResp.data.status){
            notifySuccuess(ChangeStatusMutationResp.data.message);
            getList();
        } else {
            notifyError(ChangeStatusMutationResp.data.message);
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [inputData,setInputData] = useState({});
    const [errorData,setErrorData] = useState({});
    const fileInputRef  = useRef(null);

    const formValidate = ()=>{
        const errors  = {}
    //    alert(inputData.file.type)
       if(!inputData.file ){
        errors.file = 'File field is required.'
       }else{
        if(inputData.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && inputData.file.type !== 'application/vnd.ms-excel'){
            errors.file = "please choose any xlsx or xls file";
           }
       }

      

       setErrorData(errors)

       return Object.keys(errors).length === 0;

    }

    const [UploadCbhpmFileMutation] = useUploadCbhpmFileMutation();

    const notifySuccess  = (message)=>toast.success(message)

const importHandler = async(e)=>{
    e.preventDefault();
    if(formValidate()){
        const form_data = new FormData(e.currentTarget)
        form_data.append('file',inputData.file)

        const resp = await UploadCbhpmFileMutation({token,form_data});
        fileInputRef.current.value = "";
        if(resp.data.status){
            notifySuccess(resp.data.message)
        }else{
            notifyError(resp.data.message);
        }
    }
}

  return (
    <Layout>
    <ToastContainer />
    <div className="card">
        <div className="card-header">
            <div className="row">
                <div className="col-md-6 mt-2 pagetitle">
                    <h3>CBHPM List</h3> 
                </div>
             
                <div className="col-md-6 text-end mt-3">
                    <Link className="btn btn-primary" to={`/admin/cbhpm/create`}>Add CBHPM</Link>
                </div>

                <div className="offset-md-6 col-md-6 text-end mt-3">
                <form onSubmit={importHandler} className="d-flex align-items-center mb-3">
            <div className='col-md-6'>
                <input 
                    type='file' 
                    ref={fileInputRef}
                    className={`form-control ${errorData.file ? 'is-invalid':''}`} 
                    placeholder='Select xls file' 
                    onChange={(e)=>{
                      setInputData({...inputData,file:e.target.files[0]})
                      setErrorData({...errorData,file:''});
                    }}
                />
                {errorData.file && (
                    <div className='invalid-feedback'>{errorData.file}</div>
                )}
            </div>
            <div className='col-md-6 '>
                <button type='submit' className="btn btn-primary">Import Xls file</button>
            </div>
        </form>
                </div>


            </div>
        </div>
        <div className="card-body ">
            <div className="row mb-3">
                <div className="col-md-3">
                    <input 
                        type='text' 
                        className='form-control' 
                        name='search' 
                        id='search' 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, keyword: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        value={searchInput.keyword} 
                        placeholder='Search here..' 
                    />
                </div>
                <div className="col-md-3">
                    <select 
                        name='status' 
                        value={searchInput.status} 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, status: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        className='form-control'
                    >
                        <option value=''>--Please select--</option>
                        <option value='active'>Active</option>
                        <option value='inactive'>Inactive</option>
                    </select>
                </div>
            </div>
           <div className='table-responsive'>
            <table className="table table-bordered ">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Group Id</th>
                        <th scope="col">Group Description</th>
                        <th scope="col">Sub Group Id</th>
                        <th scope="col">Sub Group Description</th>
                        <th scope="col">Procedure ID</th>
                        <th scope="col">Description Of The Procedure</th>
                        <th scope="col">Percentage</th>
                        <th scope="col">Porte</th>
                        <th scope="col">operating Cost</th>
                        <th scope="col">Aux No</th>
                        <th scope="col">Anestes Gate</th>
                        <th scope="col">Movies Or Doc</th>
                        <th scope="col">incidence</th>
                        <th scope="col">Ur</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    list.length > 0 ? list.map((value, index)=>(
                        <tr key={index}>
                           {/* <td>{index + 1}</td> */}
                           <td>{(currentPage - 1) * 10 + index + 1}</td>
                            <td>{value.groupId}</td>
                            <td>{value.groupDescription}</td>
                            <td>{value.subgroupId}</td>
                            <td>{value.subgroupDescription}</td>
                            <td>{value.procedureID}</td>
                            <td>{value.descriptionOfTheProcedure}</td>
                            <td>{value.Percentage}</td>
                            <td>{value.Porte}</td>
                            <td>{value.operatingCost}</td>
                            <td>{value.auxNo}</td>
                            <td>{value.anestesGate}</td>
                            <td>{value.moviesOrDoc}</td>
                            <td>{value.incidence}</td>
                            <td>{value.uR}</td>
                            <td>{value.status === 'active' ? (
                                <Link onClick={()=>{ changeStatus('Cbhpm', value._id, 'inactive') }} className='btn btn-success text-white'>Active</Link>
                            ):(
                                <Link onClick={()=>{ changeStatus('Cbhpm', value._id, 'active') }} className='btn btn-danger text-white'>Inactive</Link>
                            )}</td>
                            <td>
                                <Link to={`/admin/cbhpm/${value._id}/edit`} className='btn btn-warning btn-sm mx-1'><i className='bi bi-pencil'></i></Link>
                             {value._id !== '670ce09dc0ae0ae1dd9aa6ed' && (
                                <Link onClick={()=>{ deleteData(value._id) }} className='btn btn-danger btn-sm mx-1'><i className="bi bi-trash"></i></Link>
                             )}
                            </td>
                        </tr>
                    )):(
                        <tr>
                            <td colSpan="6" className='text-center'>No Record Found</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            </div>
            {totalPages > 1 && (
    <div className="d-flex justify-content-center mt-4">
        <nav>
            <ul className="pagination">
              
                {currentPage > 3 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                        </li>
                        <li className="page-item disabled">
                            <span className="page-link">...</span>
                        </li>
                    </>
                )}

                {/* Show a range of pages around the current page */}
                {Array.from({ length: totalPages }, (_, index) => index + 1)
                    .filter(page => page >= currentPage - 2 && page <= currentPage + 2)
                    .map(page => (
                        <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(page)}>
                                {page}
                            </button>
                        </li>
                    ))}

                {/* Show "..." and the last page if there are more than 2 pages left */}
                {currentPage < totalPages - 2 && (
                    <>
                        <li className="page-item disabled">
                            <span className="page-link">...</span>
                        </li>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                                {totalPages}
                            </button>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    </div>
)}

        </div>
    </div>
</Layout>
  )
}
