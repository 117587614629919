import React, { useEffect, useState } from 'react'
import Layout from '../../../master/Layout'
import { useSelector } from 'react-redux'
import { useAdminProfileMutation } from '../../../services/ProjectApi'
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { Link } from 'react-router-dom';
import Chart from 'react-apexcharts';

import Select from 'react-select'; 

export default function Dashbaord() {
  const [AdminProfileMutation] = useAdminProfileMutation();
  const [allUser, setAllUser] = useState(null);
  const token = getToken();

  const [totalAmount,setTotalAmount] = useState(0)

  const [chartInstitutionData, setChartInstitutionData] = useState({
    options: {},
    series: [],
    labels: [],
  });

  const [chartDataProfessional, setChartDataProfessional] = useState({
    options: {},
    series: [],
    labels: [],
  });




  const [chartAmountOptions, setChartAmountOptions] = useState({
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: true 
        },
      },
      xaxis: {
        categories: [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ],
        title: {
          text: "Months", 
          style: {
            fontSize: '12px',
            fontWeight: 'bold'
          }
        },
      },
      yaxis: {
        title: {
          text: "Values", 
          style: {
            fontSize: '12px',
            fontWeight: 'bold'
          }
        },
      },
      colors: ["#008FFB"], 
      grid: {
        strokeDashArray: 5 
      },
      dataLabels: {
        enabled: true 
      },
    },
    series: [
      {
        name: "Amount",
        data: []
      }
    ]
  });


  const [chartOrderOptions, setChartOrderOptions] = useState({
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: true 
        },
      },
      xaxis: {
        categories: [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ],
        title: {
          text: "Months", 
          style: {
            fontSize: '12px',
            fontWeight: 'bold'
          }
        },
      },
      yaxis: {
        title: {
          text: "Values", 
          style: {
            fontSize: '12px',
            fontWeight: 'bold'
          }
        },
      },
      colors: ["#008FFB"], 
      grid: {
        strokeDashArray: 5 
      },
      dataLabels: {
        enabled: true 
      },
    },
    series: [
     
    ]
  });




  const getAdminProfile = async (year=currentYear) => {
    try {
      const AdminProfileMutationResp = await AdminProfileMutation({ year,token });
      if (AdminProfileMutationResp?.data?.status) {
        const allUserData = AdminProfileMutationResp.data.allUser;
        setAllUser(allUserData);
        // console.log(allUserData?.amountTotal[0]?.total)

        setTotalAmount(allUserData?.amountTotal[0]?.total || 0)

        // Set chart data for organizations
        setChartInstitutionData({
          options: {},
          series: [
            allUserData.anesthesiaGroupCount,
            allUserData.hospitalCount,
            allUserData.imagingDiagnosticClinicCount,
            allUserData.LaboratoryCount,
            allUserData.PathologyClinicCount,
            allUserData.SpecialMaterialsCompanyCount,
          ],
          labels: [
            "Anesthesia Group",
            "Hospital",
            "Imaging Diagnostic Clinic",
            "Laboratory",
            "Pathology Clinic",
            "Special Materials Company",
          ],
        });

        setChartDataProfessional({
          options: {},
          series: [
            allUserData.SurgeonCount,
            allUserData.FreelanceAnesthesiologistCount,
            allUserData.GeneralPractitionerCount,
            allUserData.SpecialistClinicianCount,
            allUserData.OtherHealthProfessionalCount,
          ],
          labels: [
            "Surgeon",
            "Freelance Anesthesiologist",
            "General Practitioner",
            "Specialist Clinician",
            "Other Health Professional",
          ],
        });




        const filledAmountData = Array(12).fill(0).map((_, index) => {
          const amount = AdminProfileMutationResp.data?.amountOption.find(option => option.month === index + 1);
          
          return amount ? parseFloat(amount.count).toFixed(2) : 0;
        });

        setChartAmountOptions((prevState) => ({
          ...prevState,
          series: [
            {
              name: "Amount",
              data: filledAmountData,
            },
          ],
        }));
  
    


        // Fill missing months with 0 if not present
        const filledOrderData = Array(12).fill(0).map((_, index) => {
          // Find the order for the current month (index + 1 to convert to 1-based month)
          const order = AdminProfileMutationResp.data?.orderOption.find(option => option.month === index + 1);
          
          // Return the order count if found, otherwise return 0
          return order ? order.count : 0;
        });

        setChartOrderOptions((prevState) => ({
          ...prevState,
          series: [
            {
              name: "Product",
              data: filledOrderData,  // Using the filled order data for each month
            },
          ],
        }));



console.log(chartOrderOptions)




      }
    } catch (error) {
      console.error("Error fetching admin profile:", error);
    }
  };

  useEffect(() => {
    getAdminProfile(currentYear);
  }, []);

// console.log(chartOrderOptions)

const currentYear = new Date().getFullYear();

const years = Array.from({ length: 20 }, (_, i) => ({
  value: currentYear - i,
  label: (currentYear - i).toString(),
}));

const [selectedYear, setSelectedYear] = useState({
  value: currentYear,
  label: currentYear.toString(),
});

const handleChange = (selectedOption) => {
  setSelectedYear(selectedOption);
  console.log(selectedYear)
  getAdminProfile(selectedOption.value)
};

  return (
   <Layout>
    <div className="row">
    <div className='col-md-8'>

    </div>
<div className='col-md-4'>
<Select
            options={years}
            value={selectedYear}
            onChange={handleChange}
            isSearchable
      />
    </div>
        <div className="col-lg-12">
            <div className="row">
                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                          <Link to={'/admin/anesthesia-group'}>
                                <h5 className="card-title p-0">
                                Anesthesia Group
                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>{allUser?.anesthesiaGroupCount}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                          <Link to={'/admin/hospital'}>
                                <h5 className="card-title p-0">
                                Hospital
                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>{allUser?.hospitalCount}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                        <Link to={'/admin/imaging-diagnostic-clinic'}>
                                <h5 className="card-title p-0">
                                Imaging Diagnostic Clinic
                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>{allUser?.imagingDiagnosticClinicCount}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                        <Link to={'/admin/laboratory'}>
                                <h5 className="card-title p-0">
                                Laboratory
                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>{allUser?.LaboratoryCount}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                        <Link to={'/admin/pathology-clinic'}>
                                <h5 className="card-title p-0">
                                Pathology Clinic
                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>{allUser?.PathologyClinicCount}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                        <Link to={'/admin/special-materials-company'}>
                                <h5 className="card-title p-0">
                                Special Materials Company

                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>{allUser?.SpecialMaterialsCompanyCount}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                        <Link to={'/admin/surgeon'}>
                                <h5 className="card-title p-0">
                                Surgeon
                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>{allUser?.SurgeonCount}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                          <Link to={'/admin/freelance-anesthesiologist'}>
                                <h5 className="card-title p-0">
                                Freelance Anesthesiologist
                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>{allUser?.FreelanceAnesthesiologistCount}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                        <Link to={'/admin/general-practitioner'}>
                                <h5 className="card-title p-0">
                                General Practitioner
                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>{allUser?.GeneralPractitionerCount}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                        <Link to={'/admin/specialist-clinician'}>
                                <h5 className="card-title p-0">
                                Specialist Clinician
                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>{allUser?.SpecialistClinicianCount}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                        <Link to={'/admin/other-health-professional'}>
                                <h5 className="card-title p-0">
                                Other Health Professional
                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>{allUser?.OtherHealthProfessionalCount}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                        <Link to={'/admin/patients'}>
                                <h5 className="card-title p-0">
                                Patients
                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>{allUser?.patientCount}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

               
                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                        <Link to={'/'}>
                                <h5 className="card-title p-0">
                                Total Amount
                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>${parseInt(totalAmount).toFixed(2) || 0}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                    <div className="card info-card revenue-card clr-1">
                        <div className="card-body">
                        <Link to={'/'}>
                                <h5 className="card-title p-0">
                                Total Order
                                </h5>
                          </Link>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                <h6>{allUser?.totalOrder}</h6>
                                </div>
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-justify"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

                </div>
                
                </div>
                <h5>Amount</h5>


                <div className='col-md-12'>
                <Chart
                options={chartAmountOptions.options}
                series={chartAmountOptions.series}
                type="bar"
                height="350"
                width="100%"
              />
                </div>
                <h5>Order</h5>

                <div className='col-md-12'>
                <Chart
                options={chartOrderOptions.options}
                series={chartOrderOptions.series}
                type="line"
                height="350"
                width="100%"
              />
                </div>

                <div className='col-md-6'>
                  <h5>Healthcare Institutions</h5>
                  <Chart 
                      options={{
                        ...chartInstitutionData.options,
                        labels: chartInstitutionData.labels
                      }} 
                      series={chartInstitutionData.series} 
                      type="donut" 
                      height="350"
                              width="100%"
                    />
                </div>

                <div className='col-md-6'>
                  <h5>Healthcare Professional</h5>
                  <Chart 
                      options={{
                        ...chartDataProfessional.options,
                        labels: chartDataProfessional.labels
                      }} 
                      series={chartDataProfessional.series} 
                      type="donut" 
                      height="350"
                              width="100%"
                    />
                </div>
               
                
                </div>
   </Layout>
  )
}
