import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { removeToken } from '../services/Token';
import { useDispatch } from 'react-redux';
import { unSetUserInfo } from '../fetures/userSlice';

export default function SideBar() {

  // const [dispatch] = useDispatch();

  const navigate = useNavigate();


  const LogoutHandler = (e)=>{
    e.preventDefault();
    // alert('vikas')
    //  dispatch(unSetUserInfo());

     removeToken();
      navigate('/')

  }


  const location = useLocation();
  const currentPath = location.pathname.split('/');
  return (
   <>
    <aside id="sidebar" className="sidebar">

<ul className="sidebar-nav" id="sidebar-nav">

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'dashboard' ? '':'collapsed'} `} to={`/admin/dashboard`}>
      <i className="bi bi-grid"></i>
      <span>Dashboard</span>
    </Link>
  </li>

  {/* <li className="nav-item " >
    <Link className={`nav-link ${currentPath[2] ==  'users'|| currentPath[2] =='user' ? '':'collapsed'} `} to={`/admin/users`}>
      <i className="bi bi-menu-button-wide"></i>
      <span>Users</span>
    </Link>
  </li> */}

  {/* <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'parent-category' ? '':'collapsed'}  `} to={`/admin/parent-category`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>Parent Category</span>
    </Link>
  </li> */}

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'category' ? '':'collapsed'}  `} to={`/admin/category`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>Category</span>
    </Link>
  </li>

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'hospital-requrests' ? '':'collapsed'} `} to={`/admin/hospital-requests`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>Hospital Requests</span>
    </Link>
  </li>


  <li className="nav-item">
  <Link 
    className={`nav-link ${[].includes(currentPath[2]) ? '' : 'collapsed'}`} 
    data-bs-target="#provider-nav" 
    data-bs-toggle="collapse" 
    href="#">
    <i className="bi bi-menu-button-wide"></i><span>Provider</span><i className="bi bi-chevron-down ms-auto"></i>
  </Link>
  <ul id="provider-nav" className={`nav-content collapse ${['anesthesia-group'
  ,'hospital','imaging-diagnostic-clinic','laboratory','pathology-clinic','special-materials-company','surgeon'
  ,'freelance-anesthesiologist','general-practitioner','specialist-clinician','other-health-professional'
  ].includes(currentPath[2]) ? 'show' : ''}`} data-bs-parent="#sidebar-nav">

<li>
      <Link 
        className={`nav-link ${['anesthesia-group','hospital','imaging-diagnostic-clinic','laboratory','pathology-clinic','special-materials-company'].includes(currentPath[2]) ? '' : 'collapsed'}`} 
        data-bs-toggle="collapse" 
        href="#" 
        data-bs-target="#healthcare-institution">
        <i className="bi bi-menu-button-wide"></i><span>Healthcare Institutions</span><i className="bi bi-chevron-down ms-auto"></i>
      </Link>
      <ul id='healthcare-institution' className={`nav-content collapse ${['anesthesia-group','hospital'
      ,'imaging-diagnostic-clinic','laboratory','pathology-clinic','special-materials-company'
      ].includes(currentPath[2]) ? 'show' : ''}`} data-bs-parent="#provider-nav">
        <li className="ms-4">
          <Link 
            to={`/admin/anesthesia-group`} 
            className={`nav-link ${currentPath[2] === 'anesthesia-group' ? 'active' : ''}`}>
            <i className='bi bi-menu-button-wide'></i><span>AnesthesiaGroup</span>
          </Link>
        </li>

        <li className="ms-4">
          <Link 
            to={`/admin/hospital`} 
            className={`nav-link ${currentPath[2] === 'hospital' ? 'active' : ''}`}>
            <i className='bi bi-menu-button-wide'></i><span>Hospital</span>
          </Link>
        </li>

        <li className="ms-4">
          <Link 
            to={`/admin/imaging-diagnostic-clinic`} 
            className={`nav-link ${currentPath[2] === 'imaging-diagnostic-clinic' ? 'active' : ''}`}>
            <i className='bi bi-menu-button-wide'></i><span>Imaging DiagnosticClinic</span>
          </Link>
        </li>

        <li className="ms-4">
          <Link 
            to={`/admin/laboratory`} 
            className={`nav-link ${currentPath[2] === 'laboratory' ? 'active' : ''}`}>
            <i className='bi bi-menu-button-wide'></i><span>Laboratory</span>
          </Link>
        </li>


        <li className="ms-4">
          <Link 
            to={`/admin/pathology-clinic`} 
            className={`nav-link ${currentPath[2] === 'pathology-clinic' ? 'active' : ''}`}>
            <i className='bi bi-menu-button-wide'></i><span>PathologyClinic</span>
          </Link>
        </li>


        <li className="ms-4">
          <Link 
            to={`/admin/special-materials-company`} 
            className={`nav-link ${currentPath[2] === 'special-materials-company' ? 'active' : ''}`}>
            <i className='bi bi-menu-button-wide'></i><span>Special Materials Company	</span>
          </Link>
        </li>

      </ul>
    </li>
<li>
  <Link className={`nav-link ${['surgeon','freelance-anesthesiologist','general-practitioner','specialist-clinician','other-health-professional'].includes(currentPath[2]) ? '' :'collapsed'  }`} 
   data-bs-toggle="collapse" 
   href="#"
   data-bs-target = "#healthcare-professional"
  ><i className="bi bi-menu-button-wide"></i><span>Healthcare Professionals</span><i className="bi bi-chevron-down ms-auto"></i></Link>

<ul id='healthcare-professional' className={`nav-content collapse ${['surgeon','freelance-anesthesiologist','general-practitioner','specialist-clinician','other-health-professional'].includes(currentPath[2]) ? 'show':''  }`}  data-bs-parent="#provider-nav">
  <li className='ms-4'>
  <Link className={`nav-link ${currentPath[2] === 'surgeon' ? 'active' : '' }`} to={`/admin/surgeon`}><i className='bi bi-menu-button-wide'></i><span>Surgeon</span></Link>
  </li>

  <li className='ms-4'>
  <Link className={`nav-link ${currentPath[2] === 'freelance-anesthesiologist' ? 'active' : '' }`} to={`/admin/freelance-anesthesiologist`}><i className='bi bi-menu-button-wide'></i><span>Freelance Anesthesiologist	</span></Link>
  </li>

  <li className='ms-4'>
  <Link className={`nav-link ${currentPath[2] === 'general-practitioner' ? 'active' : '' }`} to={`/admin/general-practitioner`}><i className='bi bi-menu-button-wide'></i><span>General Practitioner</span></Link>
  </li>

  <li className='ms-4'>
  <Link className={`nav-link ${currentPath[2] === 'specialist-clinician' ? 'active' : '' }`} to={`/admin/specialist-clinician`}><i className='bi bi-menu-button-wide'></i><span>Specialist Clinician</span></Link>
  </li>

  <li className='ms-4'>
  <Link className={`nav-link ${currentPath[2] === 'other-health-professional' ? 'active' : '' }`} to={`/admin/other-health-professional`}><i className='bi bi-menu-button-wide'></i><span>Other Health Professional	</span></Link>
  </li>

</ul>

</li>

  



  </ul>
</li>


  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'patients' ? '':'collapsed'} `} to={`/admin/patients`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>Patients</span>
    </Link>
  </li>


  <li className="nav-item">
  <Link 
    className={`nav-link ${['price-management-anesthesiologist-group','price-management-freelance-anesthesiologist','price-management-pathology','price-management-surgeon','price-management-laboratory','price-management-image-diagnostic'].includes(currentPath[2]) ? '' : 'collapsed'}`} 
    data-bs-target="#price-management-nav" 
    data-bs-toggle="collapse" 
    href="#">
    <i className="bi bi-menu-button-wide"></i><span>Manage Pricing</span><i className="bi bi-chevron-down ms-auto"></i>
  </Link>
  
  <ul id="price-management-nav" className={`nav-content collapse ${['price-management-anesthesiologist-group','price-management-freelance-anesthesiologist','price-management-pathology','price-management-surgeon','price-management-laboratory','price-management-image-diagnostic'].includes(currentPath[2]) ? 'show' : ''}`} data-bs-parent="#sidebar-nav">
  
  
  <li>
      <Link 
        className={`nav-link ${currentPath[2] === 'price-management-anesthesiologist-group' ? 'active' : ''}`} 
        to={`/admin/price-management-anesthesiologist-group`}>
        <i className="bi bi-menu-button-wide"></i><span>Anesthesiologist Group</span>
      </Link>
    </li>

    <li>
      <Link 
        className={`nav-link ${currentPath[2] === 'price-management-freelance-anesthesiologist' ? 'active' : ''}`} 
        to={`/admin/price-management-freelance-anesthesiologist`}>
        <i className="bi bi-menu-button-wide"></i><span>Freelance Anesthesiologist</span>
      </Link>
    </li>

    <li>
      <Link 
        className={`nav-link ${currentPath[2] === 'price-management-pathology' ? 'active' : ''}`} 
        to={`/admin/price-management-pathology`}>
        <i className="bi bi-menu-button-wide"></i><span>Pathology</span>
      </Link>
    </li>

    <li>
      <Link 
        className={`nav-link ${currentPath[2] === 'price-management-surgeon' ? 'active' : ''}`} 
        to={`/admin/price-management-surgeon`}>
        <i className="bi bi-menu-button-wide"></i><span>Surgeon</span>
      </Link>
    </li>

    <li>
      <Link 
        className={`nav-link ${currentPath[2] === 'price-management-laboratory' ? 'active' : ''}`} 
        to={`/admin/price-management-laboratory`}>
        <i className="bi bi-menu-button-wide"></i><span>Laboratory</span>
      </Link>
    </li>

    <li>
      <Link 
        className={`nav-link ${currentPath[2] === 'price-management-image-diagnostic' ? 'active' : ''}`} 
        to={`/admin/price-management-image-diagnostic`}>
        <i className="bi bi-menu-button-wide"></i><span>Image Diagnostic</span>
      </Link>
    </li>

 
  </ul>
</li>



  <li className="nav-item">
  <Link 
    className={`nav-link ${['blogs', 'case-studies', 'videos'].includes(currentPath[2]) ? '' : 'collapsed'}`} 
    data-bs-target="#components-nav" 
    data-bs-toggle="collapse" 
    href="#">
    <i className="bi bi-menu-button-wide"></i><span>Resource</span><i className="bi bi-chevron-down ms-auto"></i>
  </Link>
  <ul id="components-nav" className={`nav-content collapse ${['blogs', 'case-studies', 'videos'].includes(currentPath[2]) ? 'show' : ''}`} data-bs-parent="#sidebar-nav">
    <li>
      <Link 
        className={` ${currentPath[2] === 'blogs' ? 'nav-link active' : ''}`} 
        to={`/admin/blogs`}>
        <i className="bi bi-menu-button-wide"></i><span>Blog</span>
      </Link>
    </li>
    <li>
      <Link 
        className={` ${currentPath[2] === 'case-studies' ? 'nav-link active' : ''}`} 
        to={`/admin/case-studies`}>
        <i className="bi bi-menu-button-wide"></i><span>Case Studies</span>
      </Link>
    </li>
    <li>
      <Link 
        className={` ${currentPath[2] === 'videos' ? ' nav-link active' : ''}`} 
        to={`/admin/videos`}>
        <i className="bi bi-menu-button-wide"></i><span>Videos</span>
      </Link>
    </li>
  </ul>
</li>

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'transactions' ? '':'collapsed'} `} to={`/admin/transactions`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>Transactions</span>
    </Link>
  </li>

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'demo-requests' ? '':'collapsed'} `} to={`/admin/demo-requests`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>Demo Requests</span>
    </Link>
  </li>

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'contact-us' ? '':'collapsed'} `} to={`/admin/contact-us`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>Contact Us</span>
    </Link>
  </li>

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'FAQ-category' ? '':'collapsed'} `} to={`/admin/FAQ-category`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>FAQ Category</span>
    </Link>
  </li>

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'FAQ' ? '':'collapsed'} `} to={`/admin/FAQ`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>FAQ</span>
    </Link>
  </li>

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'testimonials'|| currentPath[2] ==  'testimonial' ? '':'collapsed'} `} to={`/admin/testimonials`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>Testimonial</span>
    </Link>
  </li>

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'reviews' ? '':'collapsed'} `} to={`/admin/reviews`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>Reviews</span>
    </Link>
  </li>

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'supports' ? '':'collapsed'} `} to={`/admin/supports`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>Support</span>
    </Link>
  </li>

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'page' ? '':'collapsed'} `} to={`/admin/page`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>Page</span>
    </Link>
  </li>

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'settings' ? '':'collapsed'} `} to={`/admin/settings`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>Setting</span>
    </Link>
  </li>

  <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  'Cbhpm' ? '':'collapsed'} `} to={`/admin/Cbhpm`}>
    <i className="bi bi-menu-button-wide"></i>
      <span>CBHPM</span>
    </Link>
  </li>











  {/* <li className="nav-item">
    <Link className={`nav-link ${currentPath[2] ==  '' ? '':'collapsed'} `} to={`#`} onClick={LogoutHandler}>
    <i className="bi bi-menu-button-wide"></i>
      <span>Logout</span>
    </Link>
  </li> */}
  


 


</ul>

</aside>
   </>
  )
}
