import React, { useRef, useState } from 'react'
import Layout from '../../../master/Layout'
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAddPatientMutation, useAddTestimonialMutation } from '../../../services/ProjectApi';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
// import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { DatePicker } from 'rsuite';

export default function CreatePatient() {

    const nofitySuccess = (message)=>toast.success(message)
    const notifyError = (message)=>toast.error(message)

    const [inputData,setInputData] = useState({});
    const [errorData,setErrorData] = useState({});
    const [imagePreview,setImagePreview] = useState('');

    const [disableBtn,setDisableBtn] = useState(false)

    const validateForm = ()=>{
        const errors = {}

        if (!inputData.firstName || inputData.firstName.trim() === '') errors.firstName = 'FirstName field is required';
        if (!inputData.lastName || inputData.lastName.trim() === '') errors.lastName = 'LastName field is required';
        if (!inputData.email || inputData.email.trim() === '') errors.email = 'Email field is required';
        if (!inputData.dob || inputData.dob.trim() === '') errors.dob = 'Date of Birth field is required';
        if (!inputData.password || inputData.password.trim() === '') errors.password = 'Password field is required';
        if (!inputData.confirmPassword || inputData.confirmPassword.trim() === '') errors.confirmPassword = 'Confirm Password field is required';
      

        setErrorData(errors)

        return Object.keys(errors).length === 0;
    }


    const [AddPatientMutation] = useAddPatientMutation();

const token = getToken();

const handleApiError =  useApiErrorHandler();

const fileInputRef  = useRef(null);

const submitHandler = async (e)=>{
    e.preventDefault();
   if(validateForm()){
    setDisableBtn(true)
    const form_data = new FormData(e.currentTarget);
    const data = {
        firstName:form_data.get('firstName'),
        lastName:form_data.get('lastName'),
        email:form_data.get('email'),
        dob:inputData.dob,
        password:form_data.get('password'),
        confirmPassword:form_data.get('confirmPassword'),
    }
 console.log(inputData)
      const AddPatientMutationResp = await AddPatientMutation({token,data})

      console.log(AddPatientMutationResp);

      if(AddPatientMutationResp.error){
        handleApiError(AddPatientMutationResp.error)
      }

      if(AddPatientMutationResp.data.status){
        setInputData({firstName:'',lastName:'',email:'',dob:'',password:'',confirmPassword:''})

        if(fileInputRef.current){
            fileInputRef.current.value = ''
        }

        setImagePreview('')

        nofitySuccess(AddPatientMutationResp.data.message)
        
      }else{
        if(AddPatientMutationResp.data.errors){
            setErrorData(AddPatientMutationResp.data.errors)
        }
        notifyError(AddPatientMutationResp.data.message);
      }
      setDisableBtn(false)



   }
}

  return (
   <Layout>
     <div className='card'>
        <div className='card-header row'>
        <div className='col-md-6 '>
            <h5 className='pagetitle mt-3' style={{ color: '#012970' }}>Add Patient Form</h5>
        </div>
        <div className='col-md-6 text-end'>
            <Link to={`/admin/patients`} className='btn btn-danger'>Back</Link>
        </div>
        </div>
        <div className='card-body container'>
            <form className='container' onSubmit={submitHandler}>
            <ToastContainer />


            <div className='form-group'>
                    <label htmlFor='firstName'>First Name *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,firstName:e.target.value})
                        setErrorData({...errorData,firstName:''})
                    }} className={`form-control ${errorData.firstName ? 'is-invalid':''}`} name='firstName' value={inputData.firstName} id='firstName' />
                {errorData.firstName && (
                    <div className='invalid-feedback'>{errorData.firstName}</div>
                )}
                </div>

                <div className='form-group'>
                    <label htmlFor='lastName'>Last Name *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,lastName:e.target.value})
                        setErrorData({...errorData,lastName:''})
                    }} className={`form-control ${errorData.lastName ? 'is-invalid':''}`} name='lastName' value={inputData.lastName} id='lastName' />
                {errorData.lastName && (
                    <div className='invalid-feedback'>{errorData.lastName}</div>
                )}
                </div>

               
                <div className='form-group'>
                    <label htmlFor='email'>Email *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,email:e.target.value})
                        setErrorData({...errorData,email:''})
                    }} className={`form-control ${errorData.email ? 'is-invalid':''}`} name='email' value={inputData.email} id='email' />
                {errorData.email && (
                    <div className='invalid-feedback'>{errorData.email}</div>
                )}
                </div>

                <div className='form-group'>
    <label htmlFor='dob'>Date of birth *</label>
    <div className="date-group">
        <DatePicker
            oneTap
            value={inputData.dob ? new Date(inputData.dob) : null}
            onChange={(date) => {
                if (date) {
                    const formattedDate = format(date, 'yyyy-MM-dd');
                    console.log('Selected Date:', date);
                    console.log('Formatted Date:', formattedDate);
                    setInputData({ ...inputData, dob: formattedDate });
                } else {
                    setInputData({ ...inputData, dob: null });
                }
                setErrorData({ ...errorData, dob: '' });
            }}
            format='yyyy-MM-dd'
            className={`form-control ${errorData.dob ? 'is-invalid' : ''}`}
            name='dob'
            id='dob'
            placeholder="Select Date of Birth"
        />
    </div>
    {errorData.dob && (
        <div className='invalid-feedback d-block'>{errorData.dob}</div>
    )}
</div>



                <div className='form-group'>
                    <label htmlFor='password'>Password *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,password:e.target.value})
                        setErrorData({...errorData,password:''})
                    }} className={`form-control ${errorData.password ? 'is-invalid':''}`} name='password' value={inputData.password} id='password' />
                {errorData.password && (
                    <div className='invalid-feedback'>{errorData.password}</div>
                )}
                </div>


                <div className='form-group'>
                    <label htmlFor='confirmPassword'>Confirm Password *</label>
                    <input type='text' onChange={(e)=>{
                        setInputData({...inputData,confirmPassword:e.target.value})
                        setErrorData({...errorData,confirmPassword:''})
                    }} className={`form-control ${errorData.confirmPassword ? 'is-invalid':''}`} name='confirmPassword' value={inputData.confirmPassword} id='confirmPassword' />
                {errorData.confirmPassword && (
                    <div className='invalid-feedback'>{errorData.confirmPassword}</div>
                )}
                </div>

              

               

             

                <div className=' my-3'>
                <input type='submit' disabled={disableBtn} className='btn btn-primary mr-3' style={{ marginRight:`5px` }} value={`Submit`} />
                <Link to={`/admin/patients`} className='btn btn-danger ml-3'>Back</Link>
        </div>
           
            </form>

      
      
        </div>
    </div>
   </Layout>
  )
}
