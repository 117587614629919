

import React, { useEffect, useState } from 'react';
import Layout from '../../../master/Layout';
import { toast, ToastContainer } from 'react-toastify';
import { getToken } from '../../../services/Token';
import { useApiErrorHandler } from '../../../utils/useApiErrorHandler';
import { Link } from 'react-router-dom';
import { BASEURL } from '../../../Config';
import { useChangeStatusMutation, useRequestDemoListMutation } from '../../../services/ProjectApi';

export default function DemoRequest() {
    const notifyError = (message) => toast.error(message);
    const notifySuccess = (message) => toast.success(message);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [list, setList] = useState([]);
    const [searchInput, setSearchInput] = useState({ keyword: '', status: '' });

    const token = getToken();
    const handleApiError = useApiErrorHandler();

    const [TestimonialListMutation] = useRequestDemoListMutation();
    // const [ChangeStatusMutation] = useChangeStatusMutation();
    // const [DeleteTestimonialMutation] = useDeleteTestimonialMutation();

    const getList = async () => {
        try {
            const response = await TestimonialListMutation({
                token,
                keyword: searchInput.keyword,
                status: searchInput.status,
                page: currentPage
            });

            if (response.error) {
                handleApiError(response.error);
                return;
            }

            if (response.data.status) {
                setList(response.data.data);
                setTotalPages(response.data.pagination.last_page);
            } else {
                notifyError("Failed to fetch testimonials.");
            }
        } catch (error) {
            console.log(error)
            notifyError("An unexpected error occurred.");
        }
    };

    useEffect(() => {
        getList();
    }, [currentPage]); 

    useEffect(() => {
        const delayFun = setTimeout(() => {
            setCurrentPage(1); 
            getList();
        }, 500); 
        return () => clearTimeout(delayFun);
    }, [searchInput]); 

    // const deleteData = async (id) => {
    //     if (window.confirm('Are you sure you want to delete this?')) {

    //         const deleteResponse = await DeleteTestimonialMutation({token,id})

    //         if(deleteResponse.data.status){
    //             getList();
    //             notifySuccess(deleteResponse.data.message);
    //         } else {
    //             notifyError(deleteResponse.data.message);
    //         }
    //     }
    // };

    // const changeStatus = async (table_name, id, status) => {
    //     try {
    //         const form_data = { table_name, id, status };
    //         const response = await ChangeStatusMutation({ token, form_data });

    //         if (response.data.status) {
    //             notifySuccess(response.data.message);
    //             getList();
    //         } else {
    //             notifyError(response.data.message);
    //         }
    //     } catch (error) {
    //         notifyError("Failed to change status.");
    //     }
    // };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <Layout>
            <ToastContainer />
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6 mt-2 pagetitle">
                            <h3>Demo Request List</h3>
                        </div>
                      
                    </div>
                </div>
                <div className="card-body">
                    <div className="row mb-3">
                        <div className="col-md-3">
                            <input
                                type='text'
                                className='form-control'
                                name='search'
                                id='search'
                                onChange={(e) =>{ setSearchInput({ ...searchInput, keyword: e.target.value })
                                setCurrentPage(1); 
                                }}
                                value={searchInput.keyword}
                                placeholder='Search here..'
                            />
                        </div>
                        <div className="col-md-3">
                            {/* <select
                                name='status'
                                value={searchInput.status}
                                onChange={(e) => setSearchInput({ ...searchInput, status: e.target.value })}
                                className='form-control'
                            >
                                <option value=''>--Please select--</option>
                                <option value='active'>Active</option>
                                <option value='inactive'>Inactive</option>
                            </select> */}
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.length > 0 ? list.map((value, index) => (
                                    <tr key={index}>
                                        <td>{(currentPage - 1) * 10 + index + 1}</td>
                                        <td>{value.email}</td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan="6" className='text-center'>No Record Found</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    {totalPages > 1 && (
                        <div className="d-flex justify-content-center">
                            <nav>
                                <ul className="pagination">
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}
