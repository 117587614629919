import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { formatPath } from '../utils/formatPath'

export default function Layout({children}) {


  const location = useLocation();

  const pathname =  location.pathname;

  const parts = pathname.split('/')

  let adminPath = '';

  if (parts.length > 1) {
    adminPath = parts[2];
  }

  let formattedPath = formatPath(adminPath);

  // Handle special cases
  formattedPath = formattedPath === 'Faq' ? 'FAQ' : formattedPath;
  formattedPath = formattedPath === 'Faq Category' ? 'FAQ Category' : formattedPath;
  formattedPath = formattedPath === 'Cbhpm' ? 'CBHPM' : formattedPath;

  return (
   <>
    <Header />

    <SideBar />
    <main id="main" class="main">
    
    <div class="pagetitle row mt-3">
    <div className='col-md-6'>
      <h1>{formattedPath}</h1>
      </div>
      <div className='col-md-6 text-end'>
      {/* <nav> */}
        <ol className="breadcrumb text-end" style={{ float:'right' }} >
          <li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
          <li className="breadcrumb-item active">{formattedPath}</li>
        </ol>
      {/* </nav> */}
    </div>
    </div>

    {children}
    </main>
    

    <Footer />
   </>
  )
}
