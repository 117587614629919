import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';


import { Link } from 'react-router-dom';
import { BASEURL } from '../../../../Config';
import { useChangeStatusMutation, useDeleteFaqCategoryMutation, useDeletePriceAnesthesiologistGroupMutation, useDeletePriceAnesthesiologistMutation, useFaqCategoryListMutation, usePriceAnesthesiologistGroupListMutation, usePriceAnesthesiologistListMutation } from '../../../../services/ProjectApi';
import { useApiErrorHandler } from '../../../../utils/useApiErrorHandler';
import { getToken } from '../../../../services/Token';
import Layout from '../../../../master/Layout';

export default function PriceAnesthesiologistGroup() {

    const notifyError = (message)=>toast.error(message);
    const notifySuccuess = (message)=>toast.success(message);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [list,setList] = useState([]);
    const [searchInput,setSearchInput] = useState({keyword:'',status:''});

    const token = getToken();

   

    const handleApiError = useApiErrorHandler();

    const [PriceAnesthesiologistGroupListMutation] = usePriceAnesthesiologistGroupListMutation();
    const [DeletePriceAnesthesiologistGroupMutation] = useDeletePriceAnesthesiologistGroupMutation();
    const [ChangeStatusMutation] = useChangeStatusMutation();


    const getList = async ()=>{
        try{
        const response = await PriceAnesthesiologistGroupListMutation({token,keyword:searchInput.keyword,status:searchInput.status,page:currentPage});
       
      if(response.error){

        handleApiError(response.error)
      }
        if(response.data.status){
            setList(response.data.data);
            setTotalPages(response.data.pagination.last_page);
        }
    } catch (error) {
        console.log(error)
        notifyError("An unexpected error occurred.");
    }
    }

    useEffect(()=>{
        getList();
    },[currentPage]);

    useEffect(()=>{
        const delayFun = setTimeout(()=>{
            getList();
        },0);
        return () => clearTimeout(delayFun);
    },[searchInput]);

    const deleteData = async (id)=>{
        if(window.confirm('Are you sure you want to delete this ?')){
            const deleteResponse = await DeletePriceAnesthesiologistGroupMutation({token,id});
            if(deleteResponse.data.status){
              console.log(deleteResponse.data)
              notifySuccuess(deleteResponse.data.message);
                getList();
            } else {
                notifyError(deleteResponse.data.message);
            }
        }
    }

    const changeStatus = async (table_name, id, status)=>{
        const form_data = {table_name, id, status};
        const ChangeStatusMutationResp = await ChangeStatusMutation({token, form_data});
        if(ChangeStatusMutationResp.data.status){
            notifySuccuess(ChangeStatusMutationResp.data.message);
            getList();
        } else {
            notifyError(ChangeStatusMutationResp.data.message);
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };




  return (
    <Layout>
    <ToastContainer />
    <div className="card">
        <div className="card-header">
            <div className="row">
                <div className="col-md-6 mt-2 pagetitle">
                    <h3>Price Anesthesiologist Group List</h3> 
                </div>
                <div className="col-md-6 text-end mt-3">
                    <Link className="btn btn-primary" to={`/admin/price-management-anesthesiologist-group/create`}>Add Price Anesthesiologist</Link>
                </div>
            </div>
        </div>
        <div className="card-body ">
            <div className="row mb-3">
                <div className="col-md-3">
                    <input 
                        type='text' 
                        className='form-control' 
                        name='search' 
                        id='search' 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, keyword: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        value={searchInput.keyword} 
                        placeholder='Search here..' 
                    />
                </div>
                <div className="col-md-3">
                    <select 
                        name='status' 
                        value={searchInput.status} 
                        onChange={(e)=>{
                            setSearchInput({...searchInput, status: e.target.value})
                            setCurrentPage(1); 
                        }} 
                        className='form-control'
                    >
                        <option value=''>--Please select--</option>
                        <option value='active'>Active</option>
                        <option value='inactive'>Inactive</option>
                    </select>
                </div>
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Anesthesic Complexity</th>
                        <th scope="col">Price</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    list.length > 0 ? list.map((value, index)=>(
                        <tr key={index}>
                           {/* <td>{index + 1}</td> */}
                           <td>{(currentPage - 1) * 10 + index + 1}</td>
                            <td>{value.anesthesicComplexity}</td>
                            <td>{value.price}</td>
                            <td>{value.status === 'active' ? (
                                <Link onClick={()=>{ changeStatus('AdminPriceAesthesiologist', value._id, 'inactive') }} className='btn btn-success text-white'>Active</Link>
                            ):(
                                <Link onClick={()=>{ changeStatus('AdminPriceAesthesiologist', value._id, 'active') }} className='btn btn-danger text-white'>Inactive</Link>
                            )}</td>
                            <td>
                                <Link to={`/admin/price-management-anesthesiologist-group/${value._id}/edit`} className='btn btn-warning btn-sm mx-1'><i className='bi bi-pencil'></i></Link>
                                <Link onClick={()=>{ deleteData(value._id) }} className='btn btn-danger btn-sm mx-1'><i className="bi bi-trash"></i></Link>
                            </td>
                        </tr>
                    )):(
                        <tr>
                            <td colSpan="6" className='text-center'>No Record Found</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            {totalPages > 1 && (
                        <div className="d-flex justify-content-center">
                            <nav>
                                <ul className="pagination">
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    )}
        </div>
    </div>
</Layout>
  )
}
