import React, { useEffect, useState } from "react";
import Layout from "../../../master/Layout";
import { toast, ToastContainer } from "react-toastify";
import { getToken } from "../../../services/Token";
import { useApiErrorHandler } from "../../../utils/useApiErrorHandler";
import {  Link } from "react-router-dom";
import { BASEURL } from "../../../Config";
import Accordion from "react-bootstrap/Accordion";
import { useEditSettingMutation, useUpdateSettingMutation } from "../../../services/ProjectApi";
import { Form } from "react-bootstrap";

export default function Setting() {
  const notifyError = (message) => toast.error(message);
  const notifySuccuess = (message) => toast.success(message);



  const token = getToken();

  const [inputData, setInputData] = useState({});
  const [errorData, setErrorData] = useState({});


  const [EditSettingMutation] = useEditSettingMutation();
  const getSettingDetail = async()=>{
    const EditSettingMutationResp =  await EditSettingMutation({token});
    if(EditSettingMutationResp.data.status){
        setInputData(EditSettingMutationResp.data.data);
    }
  }


  useEffect(() => {
    getSettingDetail();
  }, []);




  const validateForm = (type) => {
    const errors = {};
    if(type === 'home'){
    if (!inputData.headerTitle || inputData.headerTitle.trim() === '') errors.headerTitle = 'Header Title field is required';
    if (!inputData.footerTitle || inputData.footerTitle.trim() === '') errors.footerTitle = 'FooterTitle field is required';
    // if (!inputData.commisionTax || inputData.commisionTax.trim() === '') errors.commisionTax = 'commisionTax field is required';

    }


    if(type === 'contact'){
        if (!inputData.email || inputData.email.trim() === '') errors.email = 'Email  field is required';
        if (!inputData.phone || inputData.phone.trim() === '') errors.phone = 'Phone field is required';
        if (!inputData.address || inputData.address.trim() === '') errors.address = 'Address field is required';
    }


    if(type === 'commisionTax'){
      if (!inputData.commisionTax || inputData.commisionTax.trim() === '') errors.commisionTax = 'commisionTax field is required';
  
      }

    
    setErrorData(errors);
    return Object.keys(errors).length === 0;
}


    const [UpdateSettingMutation] = useUpdateSettingMutation()

  const submitHandler = async({e,type})=>{
    e.preventDefault()
        if (validateForm(type)) {
            const form_data = new FormData(e.currentTarget);
            const data = {
                _id:inputData._id,
                _method:"PUT",
                headerTitle:inputData.headerTitle,
                footerTitle:inputData.footerTitle,
                commisionTax:inputData.commisionTax,
                email:inputData.email,
                phone:inputData.phone,
                address:inputData.address,
                immediatRegister:inputData.immediatRegister,
            }
            console.log(data);
          const UpdateSettingMutationResp = await UpdateSettingMutation({token,data});
          if(UpdateSettingMutationResp.data.status){
            toast.success(UpdateSettingMutationResp.data.message)
          }
        }
    
    
  }

  return (
    <Layout>
      <ToastContainer />
      <Accordion defaultActiveKey="home">
        <Accordion.Item eventKey="home">
          <Accordion.Header>Home Setting</Accordion.Header>
          <Accordion.Body>
            <div className="card">
              <div className="card-header">
                <h5 className="pagetitle mt-3" style={{ color: "#012970" }}>
                  Edit Home Setting Form
                </h5>
              </div>
              <div className="card-body">
              <form onSubmit={(e) => submitHandler({ e, type: 'home' })}>
                  <div className="form-group">
                    <label htmlFor="HeaderTitle">Header Title</label>
                    <input
                      type="text" onChange={(e)=>{
                        setInputData({...inputData,headerTitle:e.target.value});
                        setErrorData({...errorData,headerTitle:''})
                      }} value={inputData.headerTitle}
                      className={`form-control ${errorData.headerTitle ? 'is-invalid':''}`}
                      name="headerTitle"
                    />
                    {errorData.headerTitle && (
                        <div className="invalid-feedback">{errorData.headerTitle}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="footerTitle">Footer Title</label>
                    <input
                      type="text" onChange={(e)=>{
                        setInputData({...inputData,footerTitle:e.target.value});
                        setErrorData({...errorData,footerTitle:''})
                      }} value={inputData.footerTitle}
                      className={`form-control  ${errorData.footerTitle ? 'is-invalid':''}`}
                      name="footerTitle"
                    />
                       {errorData.footerTitle && (
                        <div className="invalid-feedback">{errorData.footerTitle}</div>
                    )}
                  </div>

                  {/* <div className="form-group">
                    <label htmlFor="commisionTax">Comminsion Tax (%)</label>
                    <input
                      type="text" onChange={(e)=>{
                        const {value} = e.target;

                        const numericValue = value.replace(/\D/g, "");

                        setInputData({...inputData,commisionTax:numericValue});


                        if (value !== numericValue) {
                          setErrorData({
                            ...errorData,
                            commisionTax: "Please enter only digits",
                          });
                        } else {
                          setErrorData({ ...errorData, commisionTax: "" });
                        }

                        if (value > 100) {
                          setErrorData({
                            ...errorData,
                            commisionTax: "You should above 100 ",
                          });
                        } else {
                          // setErrorData({ ...errorData, commisionTax: "" });
                        }

                        // setErrorData({...errorData,commisionTax:''})
                      }} 
                      
                      
                      value={inputData.commisionTax}
                      className={`form-control  ${errorData.commisionTax ? 'is-invalid':''}`}
                      name="commisionTax"
                    />
                       {errorData.commisionTax && (
                        <div className="invalid-feedback">{errorData.commisionTax}</div>
                    )}
                  </div> */}

                  <div className="my-3">
                    <input
                      type="submit"
                      className="btn btn-primary mr-3"
                      style={{ marginRight: `5px` }}
                      value={`Submit`}
                    />
                 
                  </div>
                </form>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="contact">
          <Accordion.Header>Contact Us Setting</Accordion.Header>
          <Accordion.Body>
          <div className="card">
              <div className="card-header">
                <h5 className="pagetitle mt-3" style={{ color: "#012970" }}>
                  Edit Contact Us Setting Form
                </h5>
              </div>
              <div className="card-body">
              <form onSubmit={(e) => submitHandler({ e, type: 'contact' })}>
              
              <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input
                      type="text" onChange={(e)=>{
                        setInputData({...inputData,address:e.target.value});
                        setErrorData({...errorData,address:''})
                      }} value={inputData.address}
                      className={`form-control  ${errorData.address ? 'is-invalid':''}`}
                      name="address"
                    />
                       {errorData.address && (
                        <div className="invalid-feedback">{errorData.address}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text" onChange={(e)=>{
                        setInputData({...inputData,email:e.target.value});
                        setErrorData({...errorData,email:''})
                      }} value={inputData.email}
                      className={`form-control  ${errorData.email ? 'is-invalid':''}`}
                      name="email"
                    />
                     {errorData.email && (
                        <div className="invalid-feedback">{errorData.email}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input
                      type="text" onChange={(e)=>{
                        setInputData({...inputData,phone:e.target.value});
                        setErrorData({...errorData,phone:''})
                      }} value={inputData.phone}
                      className={`form-control  ${errorData.phone ? 'is-invalid':''}`}
                      name="phone"
                    />
                       {errorData.phone && (
                        <div className="invalid-feedback">{errorData.phone}</div>
                    )}
                  </div>
                  <div className="my-3">
                    <input
                      type="submit"
                      className="btn btn-primary mr-3"
                      style={{ marginRight: `5px` }}
                      value={`Submit`}
                    />
                 
                  </div>
                </form>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      <Accordion.Item eventKey="registration">
          <Accordion.Header>Registration Setting</Accordion.Header>
          <Accordion.Body>
          <div className="card">
              <div className="card-header">
                <h5 className="pagetitle mt-3" style={{ color: "#012970" }}>
                  Edit Registration Setting Form
                </h5>
              </div>
              <div className="card-body">
              <form onSubmit={(e) => submitHandler({ e, type: 'registration' })}>
              
              <div className="form-group">
                    <Form.Label>Immediat Register</Form.Label>
                    <Form.Check>
                    <Form.Check.Input name="immediatRegister" checked={inputData.immediatRegister === 'yes'} 
                    onChange={()=>{
                      setInputData({...inputData,immediatRegister:'yes'})
                    }}
                     type="radio"  value={`yes`} />
                    <Form.Check.Label>Yes</Form.Check.Label>
                    </Form.Check>
                    <Form.Check>
                    <Form.Check.Input  checked={inputData.immediatRegister === 'no'} 
                     onChange={()=>{
                      setInputData({...inputData,immediatRegister:'no'})
                    }}
                     name="immediatRegister" type="radio"  value={`no`} />
                    <Form.Check.Label>No</Form.Check.Label>
                    </Form.Check>

                       {errorData.immediatRegister && (
                        <div className="invalid-feedback">{errorData.immediatRegister}</div>
                    )}
                  </div>
               
                  <div className="my-3">
                    <input
                      type="submit"
                      className="btn btn-primary mr-3"
                      style={{ marginRight: `5px` }}
                      value={`Submit`}
                    />
                 
                  </div>
                </form>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

      <Accordion.Item eventKey="Commision">
        <Accordion.Header>Comminsion </Accordion.Header>
        <Accordion.Body>
          <div className="card">
            <div className="card-header">
            <h5 className="pagetitle mt-3" style={{ color: "#012970" }}>
                  Edit Registration Setting Form
                </h5>
            </div>

            <div className="card-body">
              <form onSubmit={(e)=>{ submitHandler({e,type:'commisionTax'}) }} >
              <div className="form-group">
                    <label htmlFor="commisionTax">Comminsion Tax (%)</label>
                    <input
                      type="text" onChange={(e)=>{
                        const {value} = e.target;

                        const numericValue = value.replace(/\D/g, "");

                        setInputData({...inputData,commisionTax:numericValue});


                        if (value !== numericValue) {
                          setErrorData({
                            ...errorData,
                            commisionTax: "Please enter only digits",
                          });
                        } else {
                          setErrorData({ ...errorData, commisionTax: "" });
                        }

                        if (value > 100) {
                          setErrorData({
                            ...errorData,
                            commisionTax: "You should above 100 ",
                          });
                        } else {
                          // setErrorData({ ...errorData, commisionTax: "" });
                        }

                        // setErrorData({...errorData,commisionTax:''})
                      }} 
                      
                      
                      value={inputData.commisionTax}
                      className={`form-control  ${errorData.commisionTax ? 'is-invalid':''}`}
                      name="commisionTax"
                    />
                       {errorData.commisionTax && (
                        <div className="invalid-feedback">{errorData.commisionTax}</div>
                    )}
                  </div>

                  <div className="my-3">
                    <input
                      type="submit"
                      className="btn btn-primary mr-3"
                      style={{ marginRight: `5px` }}
                      value={`Submit`}
                    />
                 
                  </div>
              </form>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>              

      </Accordion>
    </Layout>
  );
}
