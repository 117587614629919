import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
   <>
     <footer id="footer" class="footer">
    <div class="copyright">
      &copy; Copyright <strong><span>Our Company</span></strong>. All Rights Reserved
    </div>
    <div class="credits">
      Designed by <Link to={`/`} >Our Company</Link>
    </div>
  </footer>
   </>
  )
}
